/* eslint-disable react/react-in-jsx-scope */
import { Link } from 'react-router-dom'

const Footer = () => {
  return (
    <>
      <div className="hidden md:flex py-4 px-9 gap-12 items-center justify-between">
        <section className="font-normal font-Inter text-sm text-stone-500 leading-5 w-[32%] max-w-[32%]">
          © 2024 Noon Capital. All rights reserved.
        </section>
        <section className="flex gap-7 items-center justify-center justify-self-center">
          <Link to="/minting" className="font-normal font-Inter leading-5 text-sm text-stone-500">
            Contact
          </Link>
          <Link to="/minting" className="font-normal font-Inter leading-5 text-sm text-stone-500">
            Terms and Conditions
          </Link>
          <Link to="/minting" className="font-normal font-Inter leading-5 text-sm text-stone-500">
            Privacy Policy
          </Link>
        </section>
      </div>

      <div className="flex flex-col md:hidden p-4 gap-6 items-start justify-between">
        <section className="font-normal font-Inter text-sm text-stone-500 leading-5 w-full">
          © 2024 Noon Capital. All rights reserved.
        </section>
        <section className="flex gap-7 items-center justify-between justify-self-center">
          <Link to="/minting" className="font-normal font-Inter leading-5 text-sm text-stone-500">
            Contact
          </Link>
          <Link to="/minting" className="font-normal font-Inter leading-5 text-sm text-stone-500">
            Terms and Conditions
          </Link>
          <Link to="/minting" className="font-normal font-Inter leading-5 text-sm text-stone-500">
            Privacy Policy
          </Link>
        </section>
      </div>
    </>
  )
}

export default Footer
