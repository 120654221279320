/* eslint-disable react/react-in-jsx-scope */
import { useMemo } from 'react'

import { ExternalLinkIcon } from '@chakra-ui/icons'

import stakeSuccessIcon from '../../assets/success/success.svg'
import { formatStringToNumber } from '../../utils'
import sUSN from '../../assets/tokens/sUSN.svg'
import USN from '../../assets/tokens/USN.svg'
import { TMINT_SCREEN } from '../../types'

interface IProps {
  usnAmount: string
  usnsAmount: string
  txnExplorerURL: string
  setScreen: (screen: TMINT_SCREEN) => void
}

const StakeSuccessful = (props: IProps) => {
  const isActionDisabled = useMemo(
    () => !props.usnAmount || !Number(props.usnAmount) || !props.usnsAmount || !Number(props.usnsAmount),
    [props],
  )

  const formattedUSNInputAmount = useMemo(
    () => formatStringToNumber(Number(props.usnAmount) ? props.usnAmount : ''),
    [props, formatStringToNumber],
  )

  const formattedUSNsOutputAmount = useMemo(
    () => formatStringToNumber(Number(props.usnsAmount) ? props.usnsAmount : ''),
    [props, formatStringToNumber],
  )

  return (
    <>
      <div className="w-[72px] h-[72px] rounded-[64px] bg-green-200 p-4">
        <img src={stakeSuccessIcon} className="w-full h-full" alt="Stake Success" />
      </div>

      <p className="font-Louize font-normal text-4xl tracking-tighter text-stone-900">
        You have successfully staked <br /> USN
      </p>

      <div className="w-full rounded-xl p-2 bg-stone-100">
        <div className="w-full h-full flex flex-col md:flex-row items-center justify-between gap-2 md:items-stretch">
          <div className="grow w-full md:max-w-[49%] rounded-md bg-white gap-2 py-5 px-[51px] flex flex-col items-center justify-center flex-wrap">
            <p className="font-Suisse-Intl font-[450] text-base text-stone-500 text-center text-wrap">You staked</p>
            <div className="w-full flex items-center justify-center rounded gap-1 flex-wrap">
              <div className="w-6 h-6 shrink-0">
                <img src={USN} className="w-full h-full" alt="USN" />
              </div>
              <p className="shrink font-Suisse-Intl font-semibold text-2xl text-stone-900 text-wrap text-center max-w-full">
                {formattedUSNInputAmount} USN
              </p>
            </div>
          </div>

          <div className="grow w-full md:max-w-[49%] rounded-md bg-white gap-2 py-5 px-[51px] flex flex-col items-center justify-center flex-wrap">
            <p className="font-Suisse-Intl font-[450] text-base text-stone-500 text-center text-wrap">To get back</p>
            <div className="w-full flex items-center justify-center rounded gap-1 flex-wrap">
              <div className="w-6 h-6 shrink-0">
                <img src={sUSN} className="w-full h-full" alt="sUSN" />
              </div>
              <p className="shrink font-Suisse-Intl font-semibold text-2xl text-stone-900 text-wrap text-center max-w-full">
                {formattedUSNsOutputAmount} sUSN
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="w-full flex flex-col md:flex-row gap-4 items-center justify-center">
        <a
          href={isActionDisabled ? '' : props.txnExplorerURL || ''}
          className={`grow w-full md:w-auto flex rounded-[184px] border-[1px] py-3 px-[18px] gap-[6px] bg-stone-100 border-stone-300 items-center justify-center ${isActionDisabled ? 'cursor-not-allowed' : 'cursor-pointer'}`}
        >
          <p className="font-Suisse-Intl font-medium text-base text-stone-900">View Explorer</p>
          <ExternalLinkIcon
            height={'h-6'}
            width={'w-6'}
            className="h-6 w-6 text-stone-400"
            stroke={'text-stone-400'}
            color={'text-stone-400'}
          />
        </a>

        <button
          onClick={() => props.setScreen('input')}
          className="grow w-full md:w-auto rounded-[184px] py-3 px-[18px] gap-[6px] bg-indigo-600 font-Suisse-Intl font-medium text-base text-stone-50"
        >
          Stake More
        </button>
      </div>
    </>
  )
}

export default StakeSuccessful
