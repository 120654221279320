/* eslint-disable react/react-in-jsx-scope */
import { useAccount, useChainId, useReadContract, useWriteContract } from 'wagmi'
import { maxUint256, parseUnits, zeroAddress } from 'viem'
import { useEffect, useMemo, useState } from 'react'

import { ArrowForwardIcon, SpinnerIcon } from '@chakra-ui/icons'

import { waitForTransactionReceipt } from '@wagmi/core'

import { mainnet } from 'viem/chains'

import { CHAIN_NETWORK_CONFIG } from '../../constants/chainNetworkConfig'
import StakingVaultABI from '../../constants/abis/StakingVault.json'
import { formatBigNumber, formatStringToNumber } from '../../utils'
import { ETxnStatus, TSTAKE_SCREEN } from '../../types'
import ERC20ABI from '../../constants/abis/ERC20.json'
import NetworkFee from '../NetworkFee/NetworkFee'
import sUSN from '../../assets/tokens/sUSN.svg'
import USN from '../../assets/tokens/USN.svg'
import { DAPP_CONFIG } from '../../constants'
import SUSNPrice from '../SUSNPrice'
import USNPrice from '../USNPrice'

interface IProps {
  usnAmount: string
  setUSNAmount: (val: string) => void
  setUSNsAmount: (val: string) => void
  setScreen: (screen: TSTAKE_SCREEN) => void
}

const InputAndApproveStake = (props: IProps) => {
  const [approveButtonText, setApproveButtonText] = useState<string>('Approve')
  const [approveTxnStatus, setApproveTxnStatus] = useState<ETxnStatus>(ETxnStatus.IDLE)

  const chainId = useChainId({ config: CHAIN_NETWORK_CONFIG })
  const account = useAccount({ config: CHAIN_NETWORK_CONFIG })
  const { writeContract } = useWriteContract({ config: CHAIN_NETWORK_CONFIG })

  const usnBalance = useReadContract({
    abi: ERC20ABI,
    address: DAPP_CONFIG[chainId ?? mainnet.id].USN_ADDRESS,
    functionName: 'balanceOf',
    args: [account.address ?? zeroAddress],
    config: CHAIN_NETWORK_CONFIG,
    query: {
      enabled: true,
      refetchOnWindowFocus: true,
      refetchOnReconnect: true,
      refetchOnMount: true,
      refetchIntervalInBackground: true,
      refetchInterval: DAPP_CONFIG[chainId ?? mainnet.id].DATA_REFRESH_INTERVAL,
    },
  })

  const usnAllowance = useReadContract({
    abi: ERC20ABI,
    address: DAPP_CONFIG[chainId ?? mainnet.id].USN_ADDRESS,
    functionName: 'allowance',
    args: [account.address ?? zeroAddress, DAPP_CONFIG[chainId ?? mainnet.id].STAKING_VAULT_ADDRESS],
    config: CHAIN_NETWORK_CONFIG,
    query: {
      enabled: true,
      refetchOnWindowFocus: true,
      refetchOnReconnect: true,
      refetchOnMount: true,
      refetchIntervalInBackground: true,
      refetchInterval: DAPP_CONFIG[chainId ?? mainnet.id].DATA_REFRESH_INTERVAL,
    },
  })

  const usnsOutputAmount = useReadContract({
    abi: StakingVaultABI,
    address: DAPP_CONFIG[chainId ?? mainnet.id].STAKING_VAULT_ADDRESS,
    functionName: 'previewDeposit',
    args: [parseUnits(props.usnAmount ?? '0', 18)],
    config: CHAIN_NETWORK_CONFIG,
    query: {
      enabled: true,
      refetchOnWindowFocus: true,
      refetchOnReconnect: true,
      refetchOnMount: true,
      refetchIntervalInBackground: true,
      refetchInterval: DAPP_CONFIG[chainId ?? mainnet.id].DATA_REFRESH_INTERVAL,
    },
  })

  const isActionDisabled = useMemo(
    () =>
      !account ||
      !account.address ||
      !props.usnAmount ||
      !Number(props.usnAmount) ||
      parseUnits(props.usnAmount ?? '0', 18) > ((usnBalance.data as bigint) ?? BigInt(0)),
    [account, account.address, props, props.usnAmount],
  )

  const isUSNApprovalNeeded = useMemo(() => {
    return ((usnAllowance.data as bigint) ?? BigInt(0)) < parseUnits(props.usnAmount ?? '0', 18)
  }, [props, props.usnAmount])

  const formattedUSNBalance = useMemo(
    () => formatBigNumber((usnBalance.data as bigint) ?? BigInt(0), 18),
    [props, usnBalance, formatBigNumber],
  )

  const formattedUSNsOutputAmount = useMemo(
    () => formatBigNumber((usnsOutputAmount.data as bigint) ?? BigInt(0), 18),
    [props, formatStringToNumber, usnsOutputAmount],
  )

  useEffect(() => {
    props.setUSNsAmount(formatBigNumber((usnsOutputAmount.data as bigint) ?? BigInt(0), 18, false))
  }, [props, usnsOutputAmount])

  const onChangeUSNAmount = (event: React.FormEvent<HTMLInputElement>) => {
    const value: string = (event?.target as HTMLInputElement)?.value
    if (value === '') {
      props.setUSNAmount('')
    }

    if (!value || !Number(value)) {
      return
    }

    props.setUSNAmount(value)
  }

  const setUSNAmount = (option: 'half' | 'max') => {
    const bigIntVal = (usnBalance.data as bigint) ?? BigInt(0)
    const optionBigIntval = option === 'half' ? bigIntVal / BigInt(2) : bigIntVal > BigInt(0) ? bigIntVal : BigInt(0)
    const value = formatBigNumber(optionBigIntval, 18, false)
    props.setUSNAmount(value)
  }

  const approveUSN = async () => {
    if (!account.isConnected) {
      setApproveTxnStatus(ETxnStatus.TXN_FAILURE)
      setApproveButtonText('Try again')
      return
    }

    setApproveButtonText('Approving...')
    setApproveTxnStatus(ETxnStatus.PROCESSING)

    writeContract(
      {
        abi: ERC20ABI,
        address: DAPP_CONFIG[chainId ?? mainnet.id].USN_ADDRESS,
        functionName: 'approve',
        args: [DAPP_CONFIG[chainId ?? mainnet.id].STAKING_VAULT_ADDRESS ?? zeroAddress, maxUint256],
      },
      {
        onError: () => {
          setApproveTxnStatus(ETxnStatus.TXN_FAILURE)
          setApproveButtonText('Try again')
          setTimeout(() => {
            setApproveTxnStatus(ETxnStatus.IDLE)
            setApproveButtonText('Approve')
          }, 30 * 1000)
        },
        onSuccess: () => {
          setApproveTxnStatus(ETxnStatus.TXN_SUBMITTED_ON_CHAIN)
          setApproveButtonText('Approving...')
        },
        onSettled: async (data) => {
          const receipt = await waitForTransactionReceipt(CHAIN_NETWORK_CONFIG, { hash: data as `0x${string}` })
          if (receipt.status === 'success') {
            usnAllowance.refetch()
            setApproveTxnStatus(ETxnStatus.TXN_SUCCESS)
            setApproveButtonText('Approved')
          } else {
            setApproveTxnStatus(ETxnStatus.TXN_FAILURE)
            setApproveButtonText('Try again')
            setTimeout(() => {
              setApproveTxnStatus(ETxnStatus.IDLE)
              setApproveButtonText('Approve')
            }, 30 * 1000)
          }
        },
      },
    )
  }

  return (
    <>
      <div className="w-full flex flex-col items-start justify-between gap-1">
        <p className="w-full font-Suisse-Intl font-[450] text-base leading-6 text-stone-500">
          Enter the USN amount you would like to stake
        </p>
        <div className="w-full flex flex-col items-start justify-between gap-2">
          <div className="w-full flex rounded-lg border-[1px] p-4 gap-2 bg-stone-50 border-stone-200 items-center justify-between">
            <input
              name="stake-amount"
              id="stake-amount"
              type="text"
              className="outline-none font-Suisse-Intl font-[450] text-base leading-6 text-stone-900 bg-transaparent border-none grow border-2 border-stone-200 placeholder:text-stone-400"
              placeholder="Amount of USN to stake"
              value={props.usnAmount}
              onChange={onChangeUSNAmount}
            />
            <div
              id="stake-currency"
              className="cursor-pointer flex gap-[4px] rounded-[4px] py-[2px] px-[6px] items-center justify-between justify-self-end"
            >
              <div className="w-[18px] h-[18px] leading-6">
                <img src={USN} alt="Token image" className="w-full h-full" />
              </div>
              <p className="font-Suisse-Intl font-medium text-base leading-6 text-stone-600">USN</p>
            </div>
          </div>
          <div className="w-full flex gap-1 items-center justify-between">
            <p className="font-Suisse-Intl font-[450] text-sm text-stone-400 text-wrap max-w-[48%]">
              Available: {formattedUSNBalance} USN
            </p>
            <div className="w-fit flex gap-1 items-center justify-between">
              <button
                onClick={() => setUSNAmount('half')}
                className="rounded py-[2px] px-2 bg-stone-100 font-Suisse-Intl font-medium text-sm text-stone-500"
              >
                HALF
              </button>
              <button
                onClick={() => setUSNAmount('max')}
                className="rounded py-[2px] px-2 bg-stone-100 font-Suisse-Intl font-medium text-sm text-stone-500"
              >
                MAX
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="w-full flex gap-1 justify-between items-center">
        <p className="font-Suisse-Intl font-[450] text-base text-stone-500 text-wrap w-auto justify-self-start">
          You will receive
        </p>
        <div className="flex rounded gap-1 items-center justify-end w-[48%]">
          <div className="shrink-0 w-6 h-6 rounded-[100px] max-w-auto">
            <img src={sUSN} className="w-full h-full" alt="sUSN" />
          </div>
          <p className="min-w-[45%] font-Suisse-Intl font-semibold text-lg text-stone-950 text-wrap justify-self-end text-right">
            {formattedUSNsOutputAmount} sUSN
          </p>
        </div>
      </div>

      <hr className="w-full block border-[1px] border-stone-200" />

      <div className="w-full flex flex-col items-between justify-center gap-2">
        <SUSNPrice currency="USDC" />
        <USNPrice currency="USDC" />
        <NetworkFee action="stake" />
      </div>

      <button
        onClick={isUSNApprovalNeeded ? approveUSN : () => props.setScreen('confirm')}
        disabled={
          isActionDisabled ||
          approveTxnStatus === ETxnStatus.PROCESSING ||
          approveTxnStatus === ETxnStatus.TXN_SUBMITTED_ON_CHAIN
        }
        className={`w-full rounded-[184px] py-3 px-[18px] ${isActionDisabled ? 'bg-indigo-400' : 'bg-indigo-600'} flex gap-[6px] items-center justify-center ${approveTxnStatus === ETxnStatus.PROCESSING || approveTxnStatus === ETxnStatus.TXN_SUBMITTED_ON_CHAIN ? 'animate-pulse' : ''}`}
      >
        {(approveTxnStatus === ETxnStatus.PROCESSING || approveTxnStatus === ETxnStatus.TXN_SUBMITTED_ON_CHAIN) && (
          <SpinnerIcon
            className={`w-6 h-6 ${isActionDisabled ? 'text-indigo-200' : 'text-stone-50'} animate-spin`}
            height={'h-6'}
            width={'w-6'}
            stroke={isActionDisabled ? 'text-indigo-200' : 'text-stone-50'}
            color={isActionDisabled ? 'text-indigo-200' : 'text-stone-50'}
          />
        )}
        <p
          className={`font-Suisse-Intl font-medium text-base ${isActionDisabled ? 'text-indigo-200' : 'text-stone-50'}`}
        >
          {isUSNApprovalNeeded ? approveButtonText : 'Next'}
        </p>
        {approveTxnStatus !== ETxnStatus.PROCESSING && approveTxnStatus !== ETxnStatus.TXN_SUBMITTED_ON_CHAIN && (
          <ArrowForwardIcon
            className={`w-6 h-6 ${isActionDisabled ? 'text-indigo-200' : 'text-stone-50'}`}
            height={'h-6'}
            width={'w-6'}
            stroke={isActionDisabled ? 'text-indigo-200' : 'text-stone-50'}
            color={isActionDisabled ? 'text-indigo-200' : 'text-stone-50'}
          />
        )}
      </button>
    </>
  )
}

export default InputAndApproveStake
