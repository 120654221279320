/* eslint-disable react/react-in-jsx-scope */
import { useAccount, useChainId, useReadContract, useWriteContract } from 'wagmi'
import { parseUnits, zeroAddress } from 'viem'
import { useMemo, useState } from 'react'

import { CheckIcon, SpinnerIcon, ArrowBackIcon } from '@chakra-ui/icons'

import { waitForTransactionReceipt } from '@wagmi/core'

import { mainnet } from 'viem/chains'

import { CHAIN_NETWORK_CONFIG } from '../../constants/chainNetworkConfig'
import StakingVaultABI from '../../constants/abis/StakingVault.json'
import ERC20ABI from '../../constants/abis/ERC20.json'
import { ETxnStatus, TMINT_SCREEN } from '../../types'
import { formatStringToNumber } from '../../utils'
import NetworkFee from '../NetworkFee/NetworkFee'
import sUSN from '../../assets/tokens/sUSN.svg'
import USN from '../../assets/tokens/USN.svg'
import { DAPP_CONFIG } from '../../constants'
import SUSNPrice from '../SUSNPrice'
import USNPrice from '../USNPrice'

interface IProps {
  usnAmount: string
  usnsAmount: string
  setTxnExplorerURL: (url: string) => void
  setScreen: (screen: TMINT_SCREEN) => void
  setRefetchStakedBalance: React.Dispatch<React.SetStateAction<boolean>>
}

const StakeConfirm = (props: IProps) => {
  const [confirmButtonText, setConfirmButtonText] = useState<string>('Confirm')
  const [stakeStatus, setStakeStatus] = useState<ETxnStatus>(ETxnStatus.IDLE)

  const chainId = useChainId({ config: CHAIN_NETWORK_CONFIG })
  const account = useAccount({ config: CHAIN_NETWORK_CONFIG })
  const { writeContract } = useWriteContract({ config: CHAIN_NETWORK_CONFIG })

  const collateralBalance = useReadContract({
    abi: ERC20ABI,
    address: DAPP_CONFIG[chainId ?? mainnet.id].USN_ADDRESS,
    functionName: 'balanceOf',
    args: [account.address ?? zeroAddress],
    config: CHAIN_NETWORK_CONFIG,
    query: {
      enabled: true,
      refetchOnWindowFocus: true,
      refetchOnReconnect: true,
      refetchOnMount: true,
      refetchIntervalInBackground: true,
      refetchInterval: DAPP_CONFIG[chainId ?? mainnet.id].DATA_REFRESH_INTERVAL,
    },
  })

  const isActionDisabled = useMemo(
    () =>
      !account ||
      !account.address ||
      !props.usnAmount ||
      !props.usnsAmount ||
      !Number(props.usnAmount) ||
      !Number(props.usnsAmount) ||
      parseUnits(props.usnAmount ?? '0', 18) > ((collateralBalance.data as bigint) ?? BigInt(0)),
    [account, account.address, props],
  )

  const formattedUSNInputAmount = useMemo(
    () => formatStringToNumber(Number(props.usnAmount) ? props.usnAmount : ''),
    [props, formatStringToNumber],
  )

  const formattedUSNsOutputAmount = useMemo(
    () => formatStringToNumber(Number(props.usnsAmount) ? props.usnsAmount : ''),
    [props, formatStringToNumber],
  )

  const stakeUSN = async () => {
    if (!account.isConnected) {
      setStakeStatus(ETxnStatus.TXN_FAILURE)
      setConfirmButtonText('Try again')
      return
    }

    setStakeStatus(ETxnStatus.PROCESSING)

    writeContract(
      {
        abi: StakingVaultABI,
        address: DAPP_CONFIG[chainId ?? mainnet.id].STAKING_VAULT_ADDRESS,
        functionName: 'deposit',
        args: [parseUnits(props.usnAmount ?? '0', 18), account?.address],
      },
      {
        onError: () => {
          setStakeStatus(ETxnStatus.TXN_FAILURE)
          setConfirmButtonText('Try again')
          setTimeout(() => {
            setStakeStatus(ETxnStatus.IDLE)
            setConfirmButtonText('Confirm')
          }, 30 * 1000)
        },
        onSuccess: () => {
          setStakeStatus(ETxnStatus.TXN_SUBMITTED_ON_CHAIN)
          setConfirmButtonText('Confirming...')
        },
        onSettled: async (data) => {
          const receipt = await waitForTransactionReceipt(CHAIN_NETWORK_CONFIG, { hash: data as `0x${string}` })
          if (receipt.status === 'success') {
            props.setRefetchStakedBalance((refetch: boolean) => !refetch)
            setStakeStatus(ETxnStatus.TXN_SUCCESS)
            setConfirmButtonText('Confirmed')
            props.setTxnExplorerURL(`${account.chain?.blockExplorers.default.url}/tx/${receipt.transactionHash}`)
            props.setScreen('success')
          } else {
            setStakeStatus(ETxnStatus.TXN_FAILURE)
            setConfirmButtonText('Try again')
            setTimeout(() => {
              setStakeStatus(ETxnStatus.IDLE)
              setConfirmButtonText('Confirm')
            }, 30 * 1000)
          }
        },
      },
    )
  }

  return (
    <>
      <button
        className="w-fit flex items-start justify-between rounded-[4px] gap-1"
        onClick={() => props.setScreen('input')}
      >
        <ArrowBackIcon
          className={`w-6 h-6 text-stone-400`}
          height={'h-6'}
          width={'w-6'}
          stroke={'text-stone-400'}
          color={'text-stone-400'}
        />
        <p className="font-Suisse-Intl font-[450] text-base text-stone-500">BACK</p>
      </button>

      <p className="font-Louize font-normal text-4xl tracking-tighter text-stone-900">
        Confirm your staking transaction
      </p>

      <div className="w-full rounded-xl p-2 bg-stone-100">
        <div className="w-full h-full flex flex-col md:flex-row items-center justify-between gap-2 md:items-stretch">
          <div className="grow w-full md:max-w-[49%] rounded-md bg-white gap-2 py-5 px-[51px] flex flex-col items-center justify-center flex-wrap">
            <p className="font-Suisse-Intl font-[450] text-base text-stone-500 text-center text-wrap">Staking</p>
            <div className="w-full flex items-center justify-center rounded gap-1 flex-wrap">
              <div className="w-6 h-6 shrink-0">
                <img src={USN} className="w-full h-full" alt="USN" />
              </div>
              <p className="shrink font-Suisse-Intl font-semibold text-2xl text-stone-900 text-wrap text-center max-w-full">
                {formattedUSNInputAmount} USN
              </p>
            </div>
          </div>

          <div className="grow w-full md:max-w-[49%] rounded-md bg-white gap-2 py-5 px-[51px] flex flex-col items-center justify-center flex-wrap">
            <p className="font-Suisse-Intl font-[450] text-base text-stone-500 text-center text-wrap">To get back</p>
            <div className="w-full flex items-center justify-center rounded gap-1 flex-wrap">
              <div className="w-6 h-6 shrink-0">
                <img src={sUSN} className="w-full h-full" alt="sUSN" />
              </div>
              <p className="shrink font-Suisse-Intl font-semibold text-2xl text-stone-900 text-wrap text-center max-w-full">
                {formattedUSNsOutputAmount} sUSN
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="w-full flex flex-col items-between justify-center gap-2">
        <SUSNPrice currency="USDC" />
        <USNPrice currency="USDC" />
        <NetworkFee action="stake" />
      </div>

      <button
        onClick={stakeUSN}
        disabled={
          isActionDisabled || stakeStatus === ETxnStatus.PROCESSING || stakeStatus === ETxnStatus.TXN_SUBMITTED_ON_CHAIN
        }
        className={`w-full rounded-[184px] py-3 px-[18px] ${isActionDisabled ? 'bg-indigo-400' : 'bg-indigo-600'} flex gap-[6px] items-center justify-center ${stakeStatus === ETxnStatus.PROCESSING || stakeStatus === ETxnStatus.TXN_SUBMITTED_ON_CHAIN ? 'animate-pulse' : ''}`}
      >
        {(stakeStatus === ETxnStatus.PROCESSING || stakeStatus === ETxnStatus.TXN_SUBMITTED_ON_CHAIN) && (
          <SpinnerIcon
            className={`w-6 h-6 ${isActionDisabled ? 'text-indigo-200' : 'text-stone-50'} animate-spin`}
            height={'h-6'}
            width={'w-6'}
            stroke={isActionDisabled ? 'text-indigo-200' : 'text-stone-50'}
            color={isActionDisabled ? 'text-indigo-200' : 'text-stone-50'}
          />
        )}
        {stakeStatus !== ETxnStatus.PROCESSING &&
          stakeStatus !== ETxnStatus.TXN_FAILURE &&
          stakeStatus !== ETxnStatus.TXN_SUBMITTED_ON_CHAIN && (
            <CheckIcon
              className={`w-6 h-6 ${isActionDisabled ? 'text-indigo-200' : 'text-stone-50'}`}
              height={'h-6'}
              width={'w-6'}
              stroke={isActionDisabled ? 'text-indigo-200' : 'text-stone-50'}
              color={isActionDisabled ? 'text-indigo-200' : 'text-stone-50'}
            />
          )}
        <p
          className={`font-Suisse-Intl font-medium text-base ${isActionDisabled ? 'text-indigo-200' : 'text-stone-50'}`}
        >
          {confirmButtonText}
        </p>
      </button>
    </>
  )
}

export default StakeConfirm
