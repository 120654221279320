/* eslint-disable react/react-in-jsx-scope */
import { useEffect, useState } from 'react'

import { TSTAKE_SCREEN, TSTAKE_TAB } from '../../types'
import WithdrawSuccessful from './WithdrawSuccessful'
import WithdrawalPeriod from './WithdrawalPeriod'
import { formatBigNumber } from '../../utils'

interface IProps {
  screen: TSTAKE_SCREEN
  currentTimestamp: number
  canWithdrawTimestamp: number
  withdrawalAmountBigint: bigint
  setTab: (tab: TSTAKE_TAB) => void
  setScreen: (screen: TSTAKE_SCREEN) => void
  setRefetchStakedBalance: React.Dispatch<React.SetStateAction<boolean>>
}

const Withdraw = (props: IProps) => {
  const [txnExplorerURL, setTxnExplorerURL] = useState<string>('')
  const [usnWithdrawable, setUSNWithdrawable] = useState<string>('')
  const [usnsWithdrawable, setUSNsWithdrawable] = useState<string>('')

  useEffect(() => {
    setUSNsWithdrawable(formatBigNumber(props.withdrawalAmountBigint, 18, false))
  }, [props.withdrawalAmountBigint])

  return props.screen !== 'success' ? (
    <WithdrawalPeriod
      screen={props.screen}
      setScreen={props.setScreen}
      setUSNWithdrawable={setUSNWithdrawable}
      setUSNsWithdrawable={setUSNsWithdrawable}
      setTxnExplorerURL={setTxnExplorerURL}
      canWithdrawTimestamp={props.canWithdrawTimestamp}
      currentTimestamp={props.currentTimestamp}
      withdrawalAmountBigint={props.withdrawalAmountBigint}
      setRefetchStakedBalance={props.setRefetchStakedBalance}
    />
  ) : (
    <WithdrawSuccessful
      usnWithdrawn={usnWithdrawable}
      usnsWithdrawn={usnsWithdrawable}
      setTab={props.setTab}
      setScreen={props.setScreen}
      txnExplorerURL={txnExplorerURL}
    />
  )
}

export default Withdraw
