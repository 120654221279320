/* eslint-disable react/react-in-jsx-scope */
import { useAccount } from 'wagmi'

import {
  MintedAndRedeemed,
  UserProfile,
  StakedAndUnstaked,
  RecentTransactions,
  ChartInfoComponent,
  Holdings,
} from '../../components/Dashboard/'

import { useEffect, useState } from 'react'

import { TProtocolMetrics, TUserActivityData, TUserDashboardData } from '../../types'
import './index.css'

const DashboardPage = () => {
  const account = useAccount()
  const [userActivityData, setUserActivityData] = useState<TUserActivityData>()
  const [userDashboardData, setUserDashboardData] = useState<TUserDashboardData>()
  const [withdrawalDemand, setWithdrawalDemand] = useState<{ amount: string; timestamp: number }>()
  const [aprTimeSeriesData, setAprTimeSeriesData] = useState<TProtocolMetrics['apr_time_series']>({})

  const fetchWithdrawalDemand = async () => {
    await fetch(`${process.env.REACT_APP_BASE_API_URL ?? ''}/api/v1/withdrawal-demand/${account.address}`)
      .then(
        (res) => {
          if (res.ok) {
            return res.json()
          }

          throw new Error('Failed to fetch withdrawal demand data')
        },
        (err) => {
          throw new Error(err)
        },
      )
      .then((json) => json as { total_withdrawal_demand: string; timestamp: string })
      .then((withdrawalDemandData) =>
        setWithdrawalDemand({
          amount: withdrawalDemandData?.total_withdrawal_demand ?? '0',
          timestamp: Date.parse(withdrawalDemandData?.timestamp) ?? 0,
        }),
      )
      .catch(() => {
        return
      })
  }

  const fetchUserDashboardData = async () => {
    await fetch(`${process.env.REACT_APP_BASE_API_URL ?? ''}/api/v1/dashboard/${account.address}`)
      .then(
        (res) => {
          if (res.ok) {
            return res.json()
          }

          throw new Error('Failed to fetch user dashboard data')
        },
        (err) => {
          throw new Error(err)
        },
      )
      .then((json) => json)
      .then((userDashboardData) => setUserDashboardData(userDashboardData ?? {}))
      .catch(() => {
        return
      })
  }

  const fetchUserData = async () => {
    await fetch(`${process.env.REACT_APP_BASE_API_URL ?? ''}/api/v1/user/${account.address}`)
      .then(
        (res) => {
          if (res.ok) {
            return res.json()
          }

          throw new Error('Failed to fetch user activity data')
        },
        (err) => {
          throw new Error(err)
        },
      )
      .then((json) => json)
      .then((userActiviyData) => setUserActivityData(userActiviyData ?? {}))
      .catch(() => {
        return
      })
  }

  const fetchProtocolMetrics = async () => {
    await fetch(`${process.env.REACT_APP_BASE_API_URL ?? ''}/api/v1/protocol-metrics`)
      .then(
        (res) => {
          if (res.ok) {
            return res.json()
          }

          throw new Error('Failed to fetch protocol metrics')
        },
        (err) => {
          throw new Error(err)
        },
      )
      .then((json) => json as TProtocolMetrics)
      .then((metrics) => setAprTimeSeriesData(metrics?.apr_time_series ?? {}))
      .catch(() => {
        return
      })
  }

  useEffect(() => {
    fetchUserData()
    fetchProtocolMetrics()
    fetchWithdrawalDemand()
    fetchUserDashboardData()
  }, [account])

  return (
    <div className="h-full flex flex-col gap-12 py-8 px-4 md:px-20 items-start justify-center">
      <UserProfile />

      <div className="w-full h-fit p-0 m-0 md:min-h-[250px]">
        <div className="w-full h-full gap-4 flex flex-col md:flex-row items-end p-0 m-0">
          <ChartInfoComponent aprTimeSeriesData={aprTimeSeriesData} isEmpty={!account.address} />
          <Holdings
            isEmpty={!account.address}
            usnHoldings={userDashboardData?.user_usn_balance ?? '0'}
            sUsnHoldings={userDashboardData?.user_susn_balance ?? '0'}
            points={userDashboardData?.user_points ?? '0'}
            points24Hrs={userDashboardData?.user_points_24hr ?? '0'}
          />
        </div>
      </div>

      <MintedAndRedeemed
        connected={!!account.address}
        minted={userActivityData?.minted ?? '0'}
        redeemed={userActivityData?.redeemed ?? '0'}
      />
      <StakedAndUnstaked
        connected={!!account.address}
        withdrawalDemand={withdrawalDemand?.amount ?? '0'}
        unstaked={userActivityData?.unstaked ?? '0'}
        withdrawalDemandTimestamp={withdrawalDemand?.timestamp ?? 0}
        staked={{ usn: userActivityData?.staked?.usn ?? '0', sUsn: userActivityData?.staked?.susn ?? '0' }}
      />
      <RecentTransactions connected={!!account.address} transactions={userActivityData?.recent_transactions ?? []} />
    </div>
  )
}

export default DashboardPage
