/* eslint-disable react/react-in-jsx-scope */
import {
  InfoIcon,
  ChevronUpIcon,
  ChevronDownIcon,
  ExternalLinkIcon,
  InfoOutlineIcon,
  ArrowForwardIcon,
} from '@chakra-ui/icons'
import { ConnectButton } from '@rainbow-me/rainbowkit'
import { useMemo, useState } from 'react'
import Countdown from 'react-countdown'

import { NavLink } from 'react-router-dom'

import USDC from '../../assets/tokens/USDC.svg'
import sUSN from '../../assets/tokens/sUSN.svg'
import USN from '../../assets/tokens/USN.svg'
import { formatBigNumber } from '../../utils'

interface IProps {
  staked?: {
    usn: string
    sUsn: string
  }
  unstaked?: string
  connected?: boolean
  withdrawalDemand?: string
  withdrawalDemandTimestamp?: number
}

const StakedAndUnstaked = ({
  connected = false,
  staked = { usn: '0', sUsn: '0' },
  unstaked = '0',
  withdrawalDemand = '0',
  withdrawalDemandTimestamp = 0,
}: IProps) => {
  const [stakeInfoVisible, setStakeInfoVisible] = useState<boolean>(true)

  const stakedHolding = useMemo(() => {
    const usnHolding = formatBigNumber(BigInt(staked?.usn ?? '0'), 18, true)
    const sUsnHolding = formatBigNumber(BigInt(staked?.sUsn ?? '0'), 18, true)

    return {
      usn: usnHolding,
      sUsn: sUsnHolding,
    }
  }, [staked])

  const unStakedHolding = useMemo(() => {
    return formatBigNumber(BigInt(unstaked ?? '0'), 18, true)
  }, [unstaked])

  const withdrawalDemandHolding = useMemo(() => {
    return formatBigNumber(BigInt(withdrawalDemand ?? '0'), 18, true)
  }, [withdrawalDemand])

  const isEmpty = useMemo(() => {
    return !Number(unstaked) && !Number(staked.usn) && !Number(staked.sUsn)
  }, [unstaked, staked])

  return (
    <div className="w-full h-fit flex flex-col gap-[7px] items-start justify-start md:min-h-[201px]">
      <div className="w-full flex items-center justify-between">
        <div className="w-fit flex gap-2 items-center justify-center">
          <p className="font-Suisse-Intl font-medium text-sm text-stone-600">STAKED AND UNSTAKED</p>
          <InfoIcon
            height={'h-6'}
            width={'w-6'}
            className="h-6 w-6 text-stone-400"
            stroke={'text-stone-400'}
            color={'text-stone-400'}
          />
        </div>
        <button className="w-fit h-fit" onClick={() => setStakeInfoVisible((visibility) => !visibility)}>
          {stakeInfoVisible ? (
            <ChevronUpIcon
              className="w-6 h-6 text-stone-400"
              color={'text-stone-400'}
              stroke={'text-stone-400'}
              height={'h-6'}
              width={'w-6'}
            />
          ) : (
            <ChevronDownIcon
              className="w-6 h-6 text-stone-400"
              color={'text-stone-400'}
              stroke={'text-stone-400'}
              height={'h-6'}
              width={'w-6'}
            />
          )}
        </button>
      </div>

      {stakeInfoVisible &&
        (!connected ? (
          <div className="w-full flex flex-col rounded-xl border-[1px] p-10 gap-4 items-center justify-around bg-white border-stone-300 data-container">
            <p className="font-Suisse-Intl font-semiMedium text-xl text-stone-900 text-center text-wrap max-w-[50%]">
              It looks like you haven&apos;t staked any tokens yet. Please stake some tokens to view your data here
            </p>
            <ConnectButton.Custom>
              {({ account, chain, openConnectModal, mounted }) => {
                const connected = mounted && account && chain

                return (
                  <div
                    {...(!mounted && {
                      'aria-hidden': true,
                      style: {
                        opacity: 0,
                        pointerEvents: 'none',
                        userSelect: 'none',
                      },
                    })}
                  >
                    {(() => {
                      if (!connected) {
                        return (
                          <button
                            onClick={openConnectModal}
                            className="w-fit rounded-[184px] py-3 px-[18px] bg-indigo-600 flex gap-[6px] items-center justify-center"
                          >
                            <p className={`font-Suisse-Intl font-medium text-base text-stone-50`}>Connect Wallet</p>
                            <ArrowForwardIcon
                              className={`w-6 h-6 text-stone-50`}
                              height={'h-6'}
                              width={'w-6'}
                              stroke={'text-stone-50'}
                              color={'text-stone-50'}
                            />
                          </button>
                        )
                      }
                    })()}
                  </div>
                )
              }}
            </ConnectButton.Custom>
          </div>
        ) : isEmpty ? (
          <div className="w-full flex flex-col rounded-xl border-[1px] p-10 gap-4 items-center justify-around bg-white border-stone-300 data-container">
            <p className="font-Suisse-Intl font-semiMedium text-xl text-stone-900 text-center text-wrap max-w-[50%]">
              It looks like you haven&apos;t staked any tokens yet. Please stake some tokens to view your data here
            </p>
            <NavLink
              to={'/staking'}
              className="w-fit rounded-[184px] py-3 px-[18px] bg-indigo-600 flex gap-[6px] items-center justify-center"
            >
              <p className={`font-Suisse-Intl font-medium text-base text-stone-50`}>Stake USN</p>
              <ArrowForwardIcon
                className={`w-6 h-6 text-stone-50`}
                height={'h-6'}
                width={'w-6'}
                stroke={'text-stone-50'}
                color={'text-stone-50'}
              />
            </NavLink>
          </div>
        ) : (
          <div className="w-full gap-4 grid grid-rows-2 md:grid-rows-1 md:grid-cols-2 items-stretch">
            <div className="w-full flex flex-col rounded-xl border-[1px] p-4 gap-6 items-start justify-between bg-white border-stone-300 data-container">
              <p className="w-full font-Louize font-normal text-2xl tracking-tighter text-stone-400">Staked</p>
              <div className="w-full flex flex-col md:flex-row items-start md:items-center justify-between gap-4">
                <div className="flex flex-col items-start justify-center">
                  <p className="font-Suisse-Intl font-[450] text-sm text-stone-400">STAKED</p>
                  <div className="w-fit flex items-center justify-center gap-1">
                    <div className="w-6 h-6">
                      <img alt="USN" src={USN} className="w-full h-full" />
                    </div>
                    <p className="font-Suisse-Intl font-medium text-base text-stone-900">
                      {stakedHolding?.usn ?? '0.00'} USN
                    </p>
                  </div>
                </div>
                <div className="flex flex-col items-start justify-center">
                  <p className="font-Suisse-Intl font-[450] text-sm text-stone-400">HOLDINGS</p>
                  <div className="w-fit flex items-center justify-center gap-1">
                    <div className="w-6 h-6">
                      <img alt="sUSN" src={sUSN} className="w-full h-full" />
                    </div>
                    <p className="font-Suisse-Intl font-medium text-base text-stone-900">
                      {stakedHolding?.sUsn ?? '0.00'} sUSN
                    </p>
                  </div>
                </div>
                <div className="w-full md:w-fit flex gap-3 items-center justify-center">
                  <button className="w-full md:w-fit flex gap-1 items-center justify-center rounded-[184px] py-2 px-3 bg-stone-100 border-stone-300 border-[1px]">
                    <p className="font-Suisse-Intl font-medium text-base text-stone-900">Stake More</p>
                    <ExternalLinkIcon
                      height={'h-6'}
                      width={'w-6'}
                      className="h-6 w-6 text-stone-500"
                      stroke={'text-stone-500'}
                      color={'text-stone-500'}
                    />
                  </button>
                </div>
              </div>
            </div>

            <div className="w-full rounded-xl m-0 p-0 border-[1px] border-stone-300 data-container">
              <div className="w-full flex flex-col p-4 gap-6 items-start justify-center bg-white rounded-t-xl">
                <p className="font-Louize font-normal text-2xl tracking-tighter text-stone-400">Unstaked</p>
                <div className="w-full flex flex-col md:flex-row items-start md:items-center justify-between gap-4">
                  <div className="flex flex-col items-start justify-center">
                    <p className="font-Suisse-Intl font-[450] text-sm text-stone-400">IN WITHDRAWAL COOLDOWN</p>
                    <div className="w-fit flex items-center justify-center gap-1">
                      <div className="w-6 h-6">
                        <img alt="USN" src={USN} className="w-full h-full" />
                      </div>
                      <p className="font-Suisse-Intl font-medium text-base text-stone-900">
                        {withdrawalDemandHolding ?? '0.00'} USN
                      </p>
                    </div>
                  </div>
                  <div className="flex flex-col items-start md:items-end justify-center">
                    <p className="font-Suisse-Intl font-[450] text-sm text-stone-400">CLAIMED AMOUNT</p>
                    <div className="w-fit flex items-center justify-center gap-1">
                      <div className="w-6 h-6">
                        <img alt="USDC" src={USDC} className="w-full h-full" />
                      </div>
                      <p className="font-Suisse-Intl font-medium text-base text-stone-900">
                        {unStakedHolding ?? '0.00'} USDC
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-full flex items-center justify-start gap-2 p-2 border-t-[1px] border-t-stone-300">
                <InfoOutlineIcon
                  height={'h-6'}
                  width={'w-6'}
                  className="h-6 w-6 text-stone-500"
                  stroke={'text-stone-500'}
                  color={'text-stone-500'}
                />
                {!withdrawalDemandHolding ||
                !withdrawalDemandHolding?.length ||
                withdrawalDemandHolding === '0.00' ||
                withdrawalDemandHolding === '0' ||
                withdrawalDemandHolding === '' ? (
                  <p className="font-Suisse-Intl font-medium text-base text-stone-500">
                    You do not have an active withdraw request
                  </p>
                ) : (
                  <p>
                    <span className="font-Suisse-Intl font-medium text-base text-stone-500">
                      You can withdraw your claimable amount in
                    </span>
                    <Countdown date={withdrawalDemandTimestamp ?? 0} />
                  </p>
                )}
              </div>
            </div>
          </div>
        ))}
    </div>
  )
}

export default StakedAndUnstaked
