/* eslint-disable react/react-in-jsx-scope */

import { useAccount, useChainId, useReadContract } from 'wagmi'
import { useEffect, useMemo } from 'react'
import { zeroAddress } from 'viem'

import { mainnet } from 'viem/chains'

import { CHAIN_NETWORK_CONFIG } from '../../constants/chainNetworkConfig'
import StakingVaultABI from '../../constants/abis/StakingVault.json'
import sUSN from '../../assets/tokens/sUSN.svg'
import { DAPP_CONFIG } from '../../constants'
import { formatBigNumber } from '../../utils'

interface IProps {
  refetch: boolean
}

const StakeInfo = (props: IProps) => {
  const chainId = useChainId({ config: CHAIN_NETWORK_CONFIG })
  const account = useAccount({ config: CHAIN_NETWORK_CONFIG })

  const usnsBalance = useReadContract({
    abi: StakingVaultABI,
    address: DAPP_CONFIG[chainId ?? mainnet.id].STAKING_VAULT_ADDRESS,
    functionName: 'balanceOf',
    args: [account.address ?? zeroAddress],
    config: CHAIN_NETWORK_CONFIG,
    query: {
      enabled: true,
      refetchOnWindowFocus: true,
      refetchOnReconnect: true,
      refetchOnMount: true,
      refetchIntervalInBackground: true,
      refetchInterval: DAPP_CONFIG[chainId ?? mainnet.id].DATA_REFRESH_INTERVAL,
    },
  })

  const formattedUSNsBalance = useMemo(
    () => formatBigNumber((usnsBalance.data as bigint) ?? BigInt(0), 18),
    [usnsBalance, formatBigNumber],
  )

  useEffect(() => {
    usnsBalance.refetch()
  }, [props.refetch])

  return (
    <div className="w-full flex flex-col md:flex-row items-start justify-center md:items-center md:justify-between gap-4 p-4 rounded-t-xl border-none border-b-[1px] border-b-stone-200 bg-stone-100">
      <div className="grow flex flex-col items-start justify-between gap-1">
        <p className="font-Suisse-Intl font-[450] text-base text-stone-500">YOUR STAKED AMOUNT</p>
        <div className="w-fit flex rounded gap-1 items-center justify-end grow flex-wrap">
          <div className="shrink-0 w-6 h-6 rounded-[100px]">
            <img src={sUSN} className="w-full h-full" alt="USN" />
          </div>
          <p className="font-Suisse-Intl font-medium text-2xl text-stone-900 text-wrap">{formattedUSNsBalance} sUSN</p>
        </div>
      </div>
      <div className="grow flex flex-col items-start md:items-end justify-between gap-1">
        <p className="font-Suisse-Intl font-[450] text-base text-stone-500">LAST WEEK APR</p>
        <p className="font-Suisse-Intl font-medium text-2xl text-green-600">12.40%</p>
      </div>
    </div>
  )
}

export default StakeInfo
