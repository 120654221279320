/* eslint-disable react/react-in-jsx-scope */
import { Circle } from '@chakra-ui/react'
import { useContext } from 'react'

import { SunIcon, MoonIcon } from '@chakra-ui/icons'

import { ThemeContext } from '../../context/ThemeProvider'

interface IProps {
  classes: string
}

const ThemeMode = (props: IProps) => {
  const theme = useContext(ThemeContext)

  const setTheme = () => {
    theme.setIsLightTheme((_isLightTheme: boolean) => {
      if (_isLightTheme) {
        localStorage.setItem('noon-dapp-theme', 'dark')
      } else {
        localStorage.setItem('noon-dapp-theme', 'light')
      }

      return !_isLightTheme
    })
  }

  return (
    <button
      onClick={setTheme}
      className={`${props.classes} items-center justify-center gap-0.5 rounded-[48px] p-0.5 bg-stone-200 cursor-pointer text-stone-900`}
    >
      <Circle
        className="w-7 h-7"
        minWidth={'w-7'}
        minHeight={'w-7'}
        backgroundColor={`${!theme.isLightTheme ? 'white' : 'transparent'}`}
      >
        <MoonIcon height={'h-5'} width={'w-5'} className="h-5 w-5" color={'text-stone-900'} stroke={'text-stone-900'} />
      </Circle>
      <Circle
        className="w-7 h-7"
        minWidth={'w-7'}
        minHeight={'w-7'}
        backgroundColor={`${theme.isLightTheme ? 'white' : 'transparent'}`}
      >
        <SunIcon height={'h-5'} width={'w-5'} className="h-5 w-5" color={'text-stone-900'} stroke={'text-stone-900'} />
      </Circle>
    </button>
  )
}

export default ThemeMode
