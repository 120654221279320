/* eslint-disable react/react-in-jsx-scope */
import { useEffect, useMemo, useState } from 'react'

import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons'

import { formatBigNumberUsingAbbrevations } from '../../utils'
import ProtocolInfoChart from './ProtocolInfoChart'
import { TProtocolMetrics } from '../../types'

interface IProps {
  page: 'Minting' | 'Staking'
}

function ProtocolInfo(props: IProps) {
  const [tvlVisible, setTVLVisible] = useState<boolean>(true)
  const [chartVisible, setChartVisible] = useState<boolean>(true)
  const [protocolMetrics, setProtocolMetrics] = useState<TProtocolMetrics>({
    tvl: '0',
    usn_amount: '0',
    susn_amount: '0',
    usn_percentage: '0',
    apr_time_series: {},
    susn_percentage: '0',
  })

  const fetchProtocolMetrics = async () => {
    await fetch(`${process.env.REACT_APP_BASE_API_URL ?? ''}/api/v1/protocol-metrics`)
      .then(
        (res) => {
          if (res.ok) {
            return res.json()
          }

          throw new Error('Failed to fetch protocol metrics')
        },
        (err) => {
          throw new Error(err)
        },
      )
      .then((json) => json as TProtocolMetrics)
      .then((metrics) => setProtocolMetrics(metrics))
      .catch((err) => {
        console.error('Error fetching metrics', err)
        return
      })
  }

  useEffect(() => {
    fetchProtocolMetrics()
  })

  const formattedTVL = useMemo(() => {
    return formatBigNumberUsingAbbrevations(BigInt(protocolMetrics.tvl ?? '0'), 18)
  }, [protocolMetrics])

  const toggleTVLVisibility = () => {
    setTVLVisible((state) => !state)
  }

  const toggleChartVisibility = () => {
    setChartVisible((state) => !state)
  }

  return (
    <div className="w-full md:w-[73%] max-w-full flex flex-col gap-8 mt-16">
      <p className="w-full font-normal font-Louize text-[56px] leading-[68px] text-stone-600 tracking-tighter">
        {props.page}
      </p>
      <div className="w-full flex flex-col gap-4">
        <div className="w-full flex flex-col gap-3">
          <div className="w-full flex gap-3 items-center justify-between">
            <p className="font-[450] text-stone-500 text-sm leading-5 text-left">TOTAL VALUE LOCKED</p>

            <button className="w-6 h-6" onClick={toggleTVLVisibility}>
              {tvlVisible ? (
                <ChevronUpIcon
                  className="w-6 h-6 text-stone-400"
                  color={'text-stone-400'}
                  stroke={'text-stone-400'}
                  height={'h-6'}
                  width={'w-6'}
                />
              ) : (
                <ChevronDownIcon
                  className="w-6 h-6 text-stone-400"
                  color={'text-stone-400'}
                  stroke={'text-stone-400'}
                  height={'h-6'}
                  width={'w-6'}
                />
              )}
            </button>
          </div>
          <p className="font-Suisse-Intl font-semibold text-2xl text-stone-900">${formattedTVL}</p>
        </div>

        <div className={`${tvlVisible ? 'flex' : 'hidden'} w-full h-[14px] gap-2`}>
          <div
            className={`h-full bg-orange-600 rounded-[48px]`}
            style={{ width: protocolMetrics.usn_percentage ?? '49' + '%' }}
          ></div>
          <div
            className={`h-full bg-stone-400 rounded-[48px]`}
            style={{ width: protocolMetrics.susn_percentage ?? '49' + '%' }}
          ></div>
        </div>

        <div className={`${tvlVisible ? 'flex' : 'hidden'} h-[14px] gap-2 items-center justify-start`}>
          <div className="h-full w-[5%] bg-orange-600 rounded-[48px]"></div>
          <p className="font-[450] font-Suisse-Intl leading-[19px] text-stone-500 text-lg w-fit">USN</p>
          <p className="font-[450] font-Suisse-Intl leading-[19px] text-stone-500 text-lg w-fit">•</p>
          <p className="font-[450] font-Suisse-Intl leading-[19px] text-stone-500 text-lg w-fit">50%</p>
          <div className="h-full w-[5%] bg-stone-400 rounded-[48px]"></div>
          <p className="font-[450] font-Suisse-Intl leading-[19px] text-stone-500 text-lg w-fit">sUSN</p>
          <p className="font-[450] font-Suisse-Intl leading-[19px] text-stone-500 text-lg w-fit">•</p>
          <p className="font-[450] font-Suisse-Intl leading-[19px] text-stone-500 text-lg w-fit">50%</p>
        </div>
      </div>

      <div className="w-full flex flex-col gap-6">
        <div className="w-full flex flex-col gap-3">
          <div className="w-full flex gap-3 items-center justify-between">
            <p className="font-[450] font-Suisse-Intl leading-5 text-stone-500 text-sm">LAST 7D sUSN APR</p>
            <button className="w-6 h-6" onClick={toggleChartVisibility}>
              {chartVisible ? (
                <ChevronUpIcon
                  className="w-6 h-6 text-stone-400"
                  color={'text-stone-400'}
                  stroke={'text-stone-400'}
                  height={'h-6'}
                  width={'w-6'}
                />
              ) : (
                <ChevronDownIcon
                  className="w-6 h-6 text-stone-400"
                  color={'text-stone-400'}
                  stroke={'text-stone-400'}
                  height={'h-6'}
                  width={'w-6'}
                />
              )}
            </button>
          </div>
          <p className="font-Suisse-Intl font-semibold text-2xl text-stone-900">3.10%</p>
        </div>

        <div className={`${chartVisible ? 'block' : 'hidden'} w-full rounded-lg border-[1px] border-stone-300`}>
          <p className="w-fit font-Suisse-Intl font-[450] p-2 text-sm leading-5 text-right text-stone-400">10%</p>
          <div className="w-full h-32" id="staking-info-chart">
            <ProtocolInfoChart
              data={Object.keys(protocolMetrics?.apr_time_series ?? {}).map((key: string) => ({
                label: key,
                value: protocolMetrics?.apr_time_series[key] ? Number(protocolMetrics?.apr_time_series[key]) : 0,
              }))}
            />
          </div>
          <p className="w-fit font-Suisse-Intl font-[450] p-2 text-sm leading-5 text-right text-stone-400">0%</p>
        </div>
      </div>
    </div>
  )
}

export default ProtocolInfo
