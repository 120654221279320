/* eslint-disable react/react-in-jsx-scope */
import { useChainId, useReadContract } from 'wagmi'
import { parseUnits } from 'viem'
import { useMemo } from 'react'

import { mainnet } from 'viem/chains'

import { CHAIN_NETWORK_CONFIG } from '../../constants/chainNetworkConfig'
import StakingVaultABI from '../../constants/abis/StakingVault.json'
import sUSN from '../../assets/tokens/sUSN.svg'
import USN from '../../assets/tokens/USN.svg'
import { DAPP_CONFIG } from '../../constants'
import { formatBigNumber } from '../../utils'

const SUSNPrice = (props: { currency: string }) => {
  const chainId = useChainId({ config: CHAIN_NETWORK_CONFIG })
  const usnOutputAmount = useReadContract({
    abi: StakingVaultABI,
    address: DAPP_CONFIG[chainId ?? mainnet.id].STAKING_VAULT_ADDRESS,
    functionName: 'previewRedeem',
    args: [parseUnits('1', 18)],
    config: CHAIN_NETWORK_CONFIG,
    query: {
      enabled: true,
      refetchOnWindowFocus: true,
      refetchOnReconnect: true,
      refetchOnMount: true,
      refetchIntervalInBackground: true,
      refetchInterval: DAPP_CONFIG[chainId ?? mainnet.id].DATA_REFRESH_INTERVAL,
    },
  })

  const formattedUSNOutputAmount = useMemo(
    () => formatBigNumber((usnOutputAmount.data as bigint) ?? BigInt(0), 18),
    [props, formatBigNumber, usnOutputAmount],
  )

  return (
    <div className="w-full flex gap-6 items-center justify-between">
      <div className="w-fit flex rounded gap-1 items-center justify-between">
        <div className="w-[18px] h-[18px] rounded-[100px]">
          <img src={sUSN} className="w-full h-full" alt="sUSN" />
        </div>
        <p className="font-Suisse-Intl font-[450] text-base text-stone-500">1 sUSN equal to</p>
      </div>
      <div className="w-fit flex gap-2 items-center justify-center">
        <div className="w-fit flex rounded gap-1 items-center justify-between">
          <div className="w-[18px] h-[18px] rounded-[100px]">
            <img src={USN} className="w-full h-full" alt="USN" />
          </div>
          <p className="font-Suisse-Intl font-semibold text-base text-stone-900">{formattedUSNOutputAmount} USN</p>
        </div>
        <p className="font-Suisse-Intl font-semibold text-base text-stone-500">•</p>
        <p className="font-Suisse-Intl font-semibold text-base text-stone-500">
          {formattedUSNOutputAmount} {props.currency}
        </p>
      </div>
    </div>
  )
}

export default SUSNPrice
