import { formatUnits } from 'viem'

import numeral from 'numeral'

numeral.register('locale', 'us', {
  delimiters: {
    thousands: ',',
    decimal: '.',
  },
  abbreviations: {
    thousand: 'K',
    million: 'M',
    billion: 'B',
    trillion: 'T',
  },
  ordinal: function (number) {
    return number === 1 ? 'er' : 'ème'
  },
  currency: {
    symbol: '$',
  },
})

export const formatBigNumber = (value: bigint, decimals: number, humanize: boolean = true) => {
  let val = '0.00'

  if (value) {
    val = formatUnits(value ?? BigInt(0), decimals) ?? '0.00'
  }

  return humanize
    ? new Intl.NumberFormat('en-US', {
        maximumFractionDigits: 3,
        minimumFractionDigits: 2,
        minimumIntegerDigits: 1,
      }).format(val as `${number}`)
    : val.toString()
}

export const formatBigNumberUsingAbbrevations = (value: bigint, decimals: number, humanize: boolean = true) => {
  let val = '0.00'

  if (value) {
    val = formatUnits(value ?? BigInt(0), decimals) ?? '0.00'
  }

  return humanize
    ? new Intl.NumberFormat('en-US', {
        maximumFractionDigits: 3,
        minimumFractionDigits: 2,
        minimumIntegerDigits: 1,
        notation: 'compact',
        compactDisplay: 'short',
      }).format(val as `${number}`)
    : val.toString()
}

export const formatStringToNumber = (value: string, humanize: boolean = true) => {
  let val = 0

  if (value) {
    val = Number(value)
  }

  return humanize
    ? new Intl.NumberFormat('en-US', {
        maximumFractionDigits: 3,
        minimumFractionDigits: 2,
        minimumIntegerDigits: 1,
      }).format(val)
    : val.toString()
}
