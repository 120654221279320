export type TAddress = `0x${string}`

export type TDAPPCONFIG = {
  [id: number]: TCHAINCONFIG
}

export type TCHAINCONFIG = {
  USN_ADDRESS: TAddress
  SUSN_ADDRESS: TAddress
  COLLATERALS: TCollateral[]
  MINT_HANDLER_ADDRESS: TAddress
  STAKING_VAULT_ADDRESS: TAddress
  DATA_REFRESH_INTERVAL: number
  GAS_LIMITS: {
    [type: string]: number
  }
}

export enum ETxnStatus {
  IDLE,
  PROCESSING,
  TXN_SUBMITTED_ON_CHAIN,
  TXN_SUCCESS,
  TXN_FAILURE,
}

export enum EApiCallStatus {
  IDLE,
  PROCESSING,
  API_CALL_SUCCESS,
  API_CALL_FAILURE,
}

export type TCollateral = {
  name: string
  symbol: string
  decimals: number
  iconPath?: string
  assetsIconName: string
  contractAddress: TAddress
}

export type TSCREEN = 'input' | 'confirm' | 'success'

export type TMINT_SCREEN = TSCREEN

export type TSTAKE_TAB = 'stake' | 'unstake' | 'withdraw'

export type TSTAKE_SCREEN = TSCREEN

export type TMintOrder = {
  message: string
  user: `${'0x'}string`
  collateralAmount: bigint
  usnAmount: bigint
  nonce: number
  expiry: number
  collateralAddress: `${'0x'}string`
}

export type GasTrackerResponse = { gasPrice: string; ethPrice: string }

export type TProtocolMetrics = {
  tvl: string
  usn_amount: string
  susn_amount: string
  apr_time_series: {
    [lable: string]: string
  }
  usn_percentage: string
  susn_percentage: string
}

export type TUserDashboardData = {
  user_points: string
  user_usn_minted: string
  user_points_24hr: string
  user_usn_balance: string
  user_susn_balance: string
  user_usn_redeemed: string
  user_usn_interactions: string
}

export type TUserActivityData = {
  usn_holding: string
  susn_holding: string
  points: string
  minted: string
  redeemed: string
  staked: {
    usn: string
    susn: string
  }
  unstaked: string
  recent_transactions: {
    action: string
    status: string
    token_in: string
    token_out: string
    amount: string
    timestamp: string
    amount_in?: string
    amount_out?: string
    transaction_hash: string
  }[]
}

export type TMINT_REQUEST = {
  id: number
  order: {
    user: string
    nonce: number
    expiry: number
    usnAmount: string
    collateralAmount: string
    collateralAddress: string
  }
  created_date: string
}

export type TPENDING_REQUESTS = {
  pending_redeeming: []
  pending_minting: TMINT_REQUEST[]
}
