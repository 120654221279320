/* eslint-disable react/react-in-jsx-scope */

import { useState } from 'react'

import ProtocolInfoChart from '../ProtocolInfo/ProtocolInfoChart'
import sUSN from '../../assets/tokens/sUSN.svg'
import EmptyContainer from '../EmptyContainer'
import { TProtocolMetrics } from '../../types'

interface Iprops {
  isEmpty?: boolean
  aprTimeSeriesData?: TProtocolMetrics['apr_time_series']
}

const ChartInfoComponent = ({ isEmpty = false, aprTimeSeriesData = {} }: Iprops) => {
  const [protocolInfoTimeFrame, setProtocolInfoTimeFrame] = useState<'24H' | '7D' | '30D' | 'ALL TIME'>('7D')

  return (
    <div className="w-full h-full md:w-[58%] md:max-w-[58%] md:grow flex flex-col gap-2 items-start justify-center">
      <div className="w-full flex-col gap-1">
        <div className="w-full flex gap-1 items-center justify-start">
          <p className="font-[450] font-Suisse-Intl leading-5 text-stone-500 text-sm">LAST</p>
          <p className="font-[450] font-Suisse-Intl leading-5 text-stone-500 text-sm">{protocolInfoTimeFrame}</p>
          <div className="w-[18px] h-[18px]">
            <img src={sUSN} className="w-full h-full " alt="sUSN" />
          </div>
          <p className="font-medium font-Suisse-Intl leading-5 text-stone-900 text-sm">sUSN</p>
          <p className="font-[450] font-Suisse-Intl leading-5 text-stone-500 text-sm">APR</p>
        </div>
        <div className="w-full grid grid-cols-2 items-center justify-between">
          {isEmpty ? (
            <EmptyContainer classes="h-12 w-32 bg-stone-200" />
          ) : (
            <p className="font-Suisse-Intl font-semibold text-2xl text-stone-900">3.10%</p>
          )}
          <div className="w-fit flex items-center justify-center rounded-lg gap-[2px] p-[2px] z-40 shrink justify-self-end">
            {/* <button
                    onClick={() => setProtocolInfoTimeFrame('24H')}
                    className={`rounded-md py-[2px] px-2 font-Suisse-Intl font-[450] text-sm ${protocolInfoTimeFrame === '24H' ? 'text-stone-900  bg-white timeframe-selected-conatiner' : 'text-stone-500'}`}
                  >
                    24H
                  </button> */}
            <button
              onClick={() => setProtocolInfoTimeFrame('7D')}
              className={`rounded-md py-[2px] px-2 font-Suisse-Intl font-[450] text-sm ${protocolInfoTimeFrame === '7D' ? 'text-stone-900  bg-white timeframe-selected-conatiner' : 'text-stone-500'}`}
            >
              7D
            </button>
            {/* <button
                    onClick={() => setProtocolInfoTimeFrame('30D')}
                    className={`rounded-md py-[2px] px-2 font-Suisse-Intl font-[450] text-sm ${protocolInfoTimeFrame === '30D' ? 'text-stone-900  bg-white timeframe-selected-conatiner' : 'text-stone-500'}`}
                  >
                    30D
                  </button> */}
            {/* <button
                    onClick={() => setProtocolInfoTimeFrame('ALL TIME')}
                    className={`rounded-md py-[2px] px-2 font-Suisse-Intl font-[450] text-sm ${protocolInfoTimeFrame === 'ALL TIME' ? 'text-stone-900  bg-white timeframe-selected-conatiner' : 'text-stone-500'}`}
                  >
                    ALL TIME
                  </button> */}
          </div>
        </div>
      </div>

      <div className="w-full min-w-full rounded-lg border-[1px] border-stone-300 grow">
        {isEmpty ? (
          <EmptyContainer classes="w-full h-full min-w-full min-h-32 max-h-full max-w-full" />
        ) : (
          <>
            <p className="w-fit font-Suisse-Intl font-[450] p-2 text-sm leading-5 text-right text-stone-400">10%</p>
            <div className="min-w-full w-full h-32" id="staking-info-chart">
              <ProtocolInfoChart
                data={Object.keys(aprTimeSeriesData ?? {}).map((key: string) => ({
                  label: key,
                  value: aprTimeSeriesData[key] ? Number(aprTimeSeriesData[key]) : 0,
                }))}
              />
            </div>
            <p className="w-fit font-Suisse-Intl font-[450] p-2 text-sm leading-5 text-right text-stone-400">0%</p>
          </>
        )}
      </div>
    </div>
  )
}

export default ChartInfoComponent
