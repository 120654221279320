/* eslint-disable react/react-in-jsx-scope */
import { NavLink } from 'react-router-dom'
import { useMemo } from 'react'

import { ArrowForwardIcon, InfoOutlineIcon } from '@chakra-ui/icons'

import mintSuccessIconGroup from '../../assets/success/successGroup.svg'
import mintSuccessIcon from '../../assets/success/success.svg'
import { TCollateral, TMINT_SCREEN } from '../../types'
import { formatStringToNumber } from '../../utils'
import USN from '../../assets/tokens/USN.svg'

interface IProps {
  collateralAmount: string
  selectedCollateral: TCollateral
  setScreen: (screen: TMINT_SCREEN) => void
  setCollateralAmount: (value: string) => void
  selectCollateral: (colateral: TCollateral) => void
}

const MintSuccessful = (props: IProps) => {
  const formattedUSNOutputAmount = useMemo(
    () => formatStringToNumber(Number(props.collateralAmount) ? props.collateralAmount : ''),
    [props, formatStringToNumber],
  )

  return (
    <>
      <div className="w-[72px] h-[72px] rounded-[64px] bg-green-200 p-4">
        <img src={mintSuccessIcon} className="w-full h-full" alt="Mint success" />
      </div>

      <p className="font-Louize font-normal text-4xl tracking-tighter text-stone-900">
        You have successfully requested to mint <br />
        <div className="w-fit flex items-center justify-between gap-2">
          <div className="w-10 h-10">
            <img src={USN} className="w-full h-full" alt="USN" />
          </div>
          <p>{formattedUSNOutputAmount} USN</p>
        </div>
      </p>

      <div className="w-full flex items-start justify-between rounded-[10px] p-3 gap-2 bg-stone-100">
        <InfoOutlineIcon
          flexShrink={0}
          width={'w-6'}
          height={'h-6'}
          stroke="text-stone-900"
          color="text-stone-900"
          className="w-6 h-6 shrink-0 text-stone-900"
        />
        <div className="flex flex-col items-start justify-center gap-[2px] shrink">
          <p className="font-Suisse-Intl font-[450] text-base text-stone-900 text-wrap">
            Your USN is in the minting queue
          </p>
          <p className="font-Suisse-Intl font-[450] text-sm text-stone-500 text-wrap text-left">
            While we conduct our KYC and AML checks. Once these are complete, your USN will be minted and deposited into
            your wallet. This process should be completed within 2 hours.
          </p>
        </div>
      </div>

      <div className="relative rounded-lg bg-stone-100 h-44 w-ful flex flex-col gap-2 py-[14px] px-4 items-start justify-between overflow-hidden">
        <div className="absolute -right-5 -bottom-12 md:-right-2 md:-bottom-10 p-0 m-0 max-w-[46%] h-full max-h-full">
          <img src={mintSuccessIconGroup} alt="Mint success" className="w-full h-full p-0 m-0" />
        </div>
        <p className="min-h-fit font-Suisse-Intl font-medium text-2xl text-stone-500 text-wrap">
          Stake your USN to <br /> earn extra rewards
        </p>
        <div className="w-fit flex items-center justify-center p-2">
          <NavLink to="/staking" className="font-Suisse-Intl font-medium text-base text-stone-900">
            Stake Now
          </NavLink>
          <div className="w-6 h-6">
            <ArrowForwardIcon
              height="h-full"
              width="w-full"
              stroke="text-stone-400"
              color="text-stone-400"
              className="text-stone-400 w-full h-full"
            />
          </div>
        </div>
      </div>

      <div className="w-full flex flex-col md:flex-row gap-4 items-center justify-center">
        <button
          onClick={() => props.setScreen('input')}
          className="grow w-full md:w-auto rounded-[184px] py-3 px-[18px] gap-[6px] bg-indigo-600 font-Suisse-Intl font-medium text-base text-stone-50"
        >
          Mint More USN
        </button>
      </div>
    </>
  )
}

export default MintSuccessful
