/* eslint-disable react/react-in-jsx-scope */
import { useAccount, useChainId, useReadContract } from 'wagmi'
import { useMemo, useState } from 'react'
import { zeroAddress } from 'viem'

import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons'

import { mainnet } from 'viem/chains'

import { CHAIN_NETWORK_CONFIG } from '../../constants/chainNetworkConfig'
import ProtocolInfo from '../../components/ProtocolInfo/ProtocolInfo'
import StakingVaultABI from '../../constants/abis/StakingVault.json'
import successIconGroup from '../../assets/success/successGroup.svg'
import { TSTAKE_SCREEN, TSTAKE_TAB } from '../../types'
import StakeInfo from '../../components/StakeInfo'
import Withdraw from '../../components/Withdraw'
import UnStake from '../../components/Unstake'
import { DAPP_CONFIG } from '../../constants'
import Stake from '../../components/Stake'

const StakingPage = () => {
  const [tab, setTab] = useState<TSTAKE_TAB>('stake')
  const [screen, setScreen] = useState<TSTAKE_SCREEN>('input')
  const [refetchStakedBalance, setRefetchStakedBalance] = useState<boolean>(false)
  const [pendingUnstakeReqInfoOpen, setPendingUnstakeReqInfoOpen] = useState<boolean>(true)

  const chainId = useChainId({ config: CHAIN_NETWORK_CONFIG })
  const account = useAccount({ config: CHAIN_NETWORK_CONFIG })

  const withdrawalPeriod = useReadContract({
    abi: StakingVaultABI,
    address: DAPP_CONFIG[chainId ?? mainnet.id].STAKING_VAULT_ADDRESS,
    functionName: 'withdrawPeriod',
    args: [],
    config: CHAIN_NETWORK_CONFIG,
    query: {
      enabled: true,
      refetchOnWindowFocus: true,
      refetchOnReconnect: true,
      refetchOnMount: true,
      refetchIntervalInBackground: true,
      refetchInterval: DAPP_CONFIG[chainId ?? mainnet.id].DATA_REFRESH_INTERVAL,
    },
  })

  const withdrawalRequest = useReadContract({
    abi: StakingVaultABI,
    address: DAPP_CONFIG[chainId ?? mainnet.id].STAKING_VAULT_ADDRESS,
    functionName: 'withdrawalDemands',
    args: [account.address ?? zeroAddress],
    config: CHAIN_NETWORK_CONFIG,
    query: {
      enabled: true,
      refetchOnWindowFocus: true,
      refetchOnReconnect: true,
      refetchOnMount: true,
      refetchIntervalInBackground: true,
      refetchInterval: DAPP_CONFIG[chainId ?? mainnet.id].DATA_REFRESH_INTERVAL,
    },
  })

  const withdrawalAmountBigint = useMemo(() => {
    const withdrawalReqData = (withdrawalRequest.data ?? []) as bigint[]
    return withdrawalReqData?.at(1) ?? BigInt(0)
  }, [account, withdrawalRequest])

  const canWithdrawTimestamp = useMemo(() => {
    const period = (withdrawalPeriod.data as bigint) ?? BigInt(0)
    const withdrawalReqData = (withdrawalRequest.data ?? []) as bigint[]
    const withdrawalReqTimestampBigNumber = withdrawalReqData?.at(0) ?? BigInt(0)
    const _canWithdrawTimestamp = withdrawalReqTimestampBigNumber + period
    return Number(_canWithdrawTimestamp.toString() ?? '0') * 1000
  }, [account, withdrawalRequest])

  const currentTimestamp = useMemo(() => {
    return Date.now()
  }, [account, withdrawalAmountBigint, withdrawalPeriod, canWithdrawTimestamp])

  return (
    <div className="h-fit flex flex-col md:grid md:grid-cols-2 gap-6 py-10 px-4 md:px-20 md:py-32 items-center justify-start md:items-start md:justify-center md:justify-items-center">
      <ProtocolInfo page="Staking" />

      <div className="w-full md:w-[73%] max-w-full flex flex-col rounded-xl border-[1px] border-stone-300">
        <StakeInfo refetch={refetchStakedBalance} />
        <div
          className={`w-full flex flex-col py-4 px-3 md:p-6 gap-6 bg-white border-none stake-container-shadow ${withdrawalAmountBigint > 0 && tab !== 'withdraw' ? '' : 'rounded-b-xl'}`}
        >
          {screen === 'input' && (
            <div className="w-fit flex gap-[2px] p-[2px] rounded-xl bg-stone-100 items-center justify-between">
              <button
                onClick={() => setTab('stake')}
                className={`w-fit rounded-[10px] py-2 px-4 ${tab === 'stake' ? 'stake-tab-shadow bg-[#FFFFFF]' : ''} font-Suisse-Intl font-medium text-base leading-6 text-selected-tab-text`}
              >
                Stake
              </button>
              <button
                onClick={() => setTab('unstake')}
                className={`w-fit rounded-[10px] py-2 px-4 ${tab === 'unstake' ? 'stake-tab-shadow bg-[#FFFFFF]' : ''} font-Suisse-Intl font-medium text-base leading-6 text-selected-tab-text`}
              >
                Unstake
              </button>
              <button
                disabled={withdrawalAmountBigint === BigInt(0)}
                onClick={() => setTab('withdraw')}
                className={`w-fit rounded-[10px] py-2 px-4 ${tab === 'withdraw' ? 'stake-tab-shadow bg-[#FFFFFF]' : ''} font-Suisse-Intl font-medium text-base leading-6 text-selected-tab-text ${withdrawalAmountBigint === BigInt(0) ? 'cursor-not-allowed' : 'cursor-pointer'}`}
              >
                Withdraw
              </button>
            </div>
          )}

          {tab === 'stake' ? (
            <Stake screen={screen} setScreen={setScreen} setRefetchStakedBalance={setRefetchStakedBalance} />
          ) : tab === 'unstake' ? (
            <UnStake screen={screen} setTab={setTab} setScreen={setScreen} />
          ) : (
            <Withdraw
              screen={screen}
              setTab={setTab}
              setScreen={setScreen}
              canWithdrawTimestamp={canWithdrawTimestamp}
              currentTimestamp={currentTimestamp}
              withdrawalAmountBigint={withdrawalAmountBigint}
              setRefetchStakedBalance={setRefetchStakedBalance}
            />
          )}
        </div>

        {tab !== 'withdraw' && withdrawalAmountBigint > 0 && currentTimestamp >= canWithdrawTimestamp && (
          <div className="relative w-full gap-2 py-4 px-6 bg-stone-50 border-t-[1px] border-stone-200  mint-container-shadow rounded-b-xl border-b-0 overflow-hidden">
            <div className="w-full flex flex-col gap-3">
              <p className="font-Suisse-Intl font-[450] text-lg text-stone-900 text-wrap">
                Your funds are ready for withdrawal!
              </p>
              <button
                onClick={() => {
                  setTab('withdraw')
                  setScreen('input')
                }}
                className="w-fit font-Suisse-Intl font-medium text-sm text-stone-50 rounded-[184px] bg-indigo-600 py-[6px] px-3"
              >
                Claim Now
              </button>
            </div>
            <div className="absolute -right-7 -bottom-10 md:-right-2 md:-bottom-10 p-0 m-0 w-52 h-40">
              <img src={successIconGroup} alt="USN icons" className="w-full h-full p-0 m-0" />
            </div>
          </div>
        )}

        {tab !== 'withdraw' && withdrawalAmountBigint > 0 && currentTimestamp < canWithdrawTimestamp && (
          <div className="flex flex-col items-start justify-between gap-2 py-4 px-6 bg-stone-50 border-t-[1px] border-stone-200  mint-container-shadow rounded-b-xl border-b-0">
            <div className="w-full flex items-center justify-between gap-2">
              <p className="font-Suisse-Intl font-[450] text-lg text-stone-900">You have a pending unstake request!</p>
              {pendingUnstakeReqInfoOpen ? (
                <ChevronUpIcon
                  onClick={() => setPendingUnstakeReqInfoOpen(false)}
                  height={'h-6'}
                  width={'w-6'}
                  className="h-6 w-6 text-stone-400"
                  stroke={'text-stone-400'}
                  color={'text-stone-400'}
                />
              ) : (
                <ChevronDownIcon
                  onClick={() => setPendingUnstakeReqInfoOpen(true)}
                  height={'h-6'}
                  width={'w-6'}
                  className="h-6 w-6 text-stone-400"
                  stroke={'text-stone-400'}
                  color={'text-stone-400'}
                />
              )}
            </div>
            {pendingUnstakeReqInfoOpen && (
              <>
                <p className="w-fit max-w-full font-Suisse-Intl font-[450] text-base text-stone-500">
                  If you submit a new one, both amounts will be available for withdrawal 7 days after the latest
                  request.
                </p>
              </>
            )}
          </div>
        )}
      </div>
    </div>
  )
}

export default StakingPage
