/* eslint-disable react/react-in-jsx-scope */
import { useAccount, useChainId, useReadContract, useWriteContract } from 'wagmi'
import Countdown from 'react-countdown'
import { zeroAddress } from 'viem'

import { SpinnerIcon, CheckIcon } from '@chakra-ui/icons'

import { waitForTransactionReceipt } from '@wagmi/core'

import { useEffect, useMemo, useState } from 'react'

import { mainnet } from 'viem/chains'

import readyForWithdrawalPNG from '../../assets/withdraw/readyForWithdrawal.png'
import { CHAIN_NETWORK_CONFIG } from '../../constants/chainNetworkConfig'
import StakingVaultABI from '../../constants/abis/StakingVault.json'
import { ETxnStatus, TSTAKE_SCREEN } from '../../types'
import clock from '../../assets/withdraw/clock.svg'
import sUSN from '../../assets/tokens/sUSN.svg'
import { DAPP_CONFIG } from '../../constants'
import { formatBigNumber } from '../../utils'

interface IProps {
  screen: TSTAKE_SCREEN
  currentTimestamp: number
  canWithdrawTimestamp: number
  withdrawalAmountBigint: bigint
  setTxnExplorerURL: (url: string) => void
  setScreen: (screen: TSTAKE_SCREEN) => void
  setUSNWithdrawable: (amount: string) => void
  setUSNsWithdrawable: (amount: string) => void
  setRefetchStakedBalance: React.Dispatch<React.SetStateAction<boolean>>
}

const WithdrawalPeriod = (props: IProps) => {
  const [confirmButtonText, setConfirmButtonText] = useState<string>('Withdraw')
  const [withdrawStatus, setWithdrawStatus] = useState<ETxnStatus>(ETxnStatus.IDLE)

  const chainId = useChainId({ config: CHAIN_NETWORK_CONFIG })
  const account = useAccount({ config: CHAIN_NETWORK_CONFIG })
  const { writeContract } = useWriteContract({ config: CHAIN_NETWORK_CONFIG })

  const withdrawalAmountFormatted = useMemo(() => {
    return formatBigNumber(props.withdrawalAmountBigint, 18)
  }, [props, account, props.withdrawalAmountBigint])

  const redeemedUSN = useReadContract({
    abi: StakingVaultABI,
    address: DAPP_CONFIG[chainId ?? mainnet.id].STAKING_VAULT_ADDRESS,
    functionName: 'previewRedeem',
    args: [props.withdrawalAmountBigint ?? BigInt(0)],
    config: CHAIN_NETWORK_CONFIG,
    query: {
      enabled: true,
      refetchOnWindowFocus: true,
      refetchOnReconnect: true,
      refetchOnMount: true,
      refetchIntervalInBackground: true,
      refetchInterval: DAPP_CONFIG[chainId ?? mainnet.id].DATA_REFRESH_INTERVAL,
    },
  })

  useEffect(() => {
    const val = (redeemedUSN.data as bigint) ?? BigInt(0)
    props.setUSNWithdrawable(formatBigNumber(val, 18, false))
    props.setUSNsWithdrawable(formatBigNumber(props.withdrawalAmountBigint, 18, false))
  }, [redeemedUSN])

  const isActionDisabled = useMemo(
    () =>
      !account ||
      !account.address ||
      props.withdrawalAmountBigint === BigInt(0) ||
      props.canWithdrawTimestamp > props.currentTimestamp,
    [account, props],
  )

  const withdrawUSNs = async () => {
    if (!account.isConnected) {
      setWithdrawStatus(ETxnStatus.TXN_FAILURE)
      setConfirmButtonText('Try again')
      return
    }

    setConfirmButtonText('Withdrawing...')
    setWithdrawStatus(ETxnStatus.PROCESSING)

    writeContract(
      {
        abi: StakingVaultABI,
        address: DAPP_CONFIG[chainId ?? mainnet.id].STAKING_VAULT_ADDRESS,
        functionName: 'redeem',
        args: [
          props.withdrawalAmountBigint ?? BigInt(0),
          account?.address ?? zeroAddress,
          account?.address ?? zeroAddress,
        ],
      },
      {
        onError: () => {
          setWithdrawStatus(ETxnStatus.TXN_FAILURE)
          setConfirmButtonText('Try again')
          setTimeout(() => {
            setWithdrawStatus(ETxnStatus.IDLE)
            setConfirmButtonText('Withdraw')
          }, 30 * 1000)
        },
        onSuccess: () => {
          setWithdrawStatus(ETxnStatus.TXN_SUBMITTED_ON_CHAIN)
          setConfirmButtonText('Withdrawing...')
        },
        onSettled: async (data) => {
          const receipt = await waitForTransactionReceipt(CHAIN_NETWORK_CONFIG, { hash: data as `0x${string}` })
          props.setRefetchStakedBalance((refetch: boolean) => !refetch)
          if (receipt.status === 'success') {
            setWithdrawStatus(ETxnStatus.TXN_SUCCESS)
            setConfirmButtonText('Withdrawn')
            props.setTxnExplorerURL(`${account.chain?.blockExplorers.default.url}/tx/${receipt.transactionHash}`)
            props.setScreen('success')
          } else {
            setWithdrawStatus(ETxnStatus.TXN_FAILURE)
            setConfirmButtonText('Try again')
            setTimeout(() => {
              setWithdrawStatus(ETxnStatus.IDLE)
              setConfirmButtonText('Withdraw')
            }, 30 * 1000)
          }
        },
      },
    )
  }

  return (
    <div className="w-full flex flex-col items-center justify-center gap-6">
      <div className="w-full flex flex-col items-center justify-center gap-1">
        <p className="w-full font-Louize font-normal text-4xl text-center tracking-tighter text-stone-900">
          {props.canWithdrawTimestamp > props.currentTimestamp ? (
            <>
              You can withdraw your <br /> funds to your wallet in
            </>
          ) : (
            <>
              Your funds are ready for <br /> withdrawal{' '}
            </>
          )}
        </p>

        <div className="w-36 h-36">
          {props.canWithdrawTimestamp > props.currentTimestamp ? (
            <img src={clock} className="w-full h-full" alt="Clock" />
          ) : (
            <img src={readyForWithdrawalPNG} className="w-full h-full" alt="Ready for withdrawal" />
          )}
        </div>

        {props.canWithdrawTimestamp > props.currentTimestamp && (
          <p className="w-full font-Suisse-Intl font-medium text-5xl text-center text-stone-900">
            <Countdown date={props.canWithdrawTimestamp} />
          </p>
        )}
      </div>

      <div className="w-full flex flex-col items-center justify-center gap-[2px]">
        <p className="font-Suisse-Intl font-[450] text-base text-stone-500">WITHDRAW AMOUNT</p>
        <div className="flex items-center justify-center gap-1">
          <div className="w-6 h-6">
            <img src={sUSN} className="w-full h-full" alt="sUSN" />
          </div>
          <p className="font-Suisse-Intl font-semibold text-2xl text-stone-900">{withdrawalAmountFormatted} sUSN</p>
        </div>
      </div>

      <button
        onClick={withdrawUSNs}
        disabled={
          isActionDisabled ||
          withdrawStatus === ETxnStatus.PROCESSING ||
          withdrawStatus === ETxnStatus.TXN_SUBMITTED_ON_CHAIN
        }
        className={`w-full flex items-center justify-center rounded-[184px] gap-[6px] py-3 px-[18px] ${isActionDisabled ? ' bg-indigo-400' : 'bg-indigo-600'} ${withdrawStatus === ETxnStatus.PROCESSING || withdrawStatus === ETxnStatus.TXN_SUBMITTED_ON_CHAIN ? 'animate-pulse' : ''}`}
      >
        {(withdrawStatus === ETxnStatus.PROCESSING || withdrawStatus === ETxnStatus.TXN_SUBMITTED_ON_CHAIN) && (
          <SpinnerIcon
            className={`w-6 h-6 ${isActionDisabled ? 'text-indigo-200' : 'text-stone-50'} animate-spin`}
            height={'h-6'}
            width={'w-6'}
            stroke={isActionDisabled ? 'text-indigo-200' : 'text-stone-50'}
            color={isActionDisabled ? 'text-indigo-200' : 'text-stone-50'}
          />
        )}
        {withdrawStatus !== ETxnStatus.PROCESSING &&
          withdrawStatus !== ETxnStatus.TXN_FAILURE &&
          withdrawStatus !== ETxnStatus.TXN_SUBMITTED_ON_CHAIN && (
            <CheckIcon
              className={`w-6 h-6 ${isActionDisabled ? 'text-indigo-200' : 'text-stone-50'}`}
              height={'h-6'}
              width={'w-6'}
              stroke={isActionDisabled ? 'text-indigo-200' : 'text-stone-50'}
              color={isActionDisabled ? 'text-indigo-200' : 'text-stone-50'}
            />
          )}
        <p
          className={`font-Suisse-Intl font-medium text-base ${isActionDisabled ? ' text-indigo-200' : 'text-stone-50'}`}
        >
          {props.canWithdrawTimestamp > props.currentTimestamp
            ? 'Your withdrawal is in progress...'
            : confirmButtonText}
        </p>
      </button>
    </div>
  )
}

export default WithdrawalPeriod
