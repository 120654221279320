/* eslint-disable react/react-in-jsx-scope */
import { useAccount, useChainId, useReadContract } from 'wagmi'
import { useEffect, useMemo, useState } from 'react'
import { parseUnits, zeroAddress } from 'viem'

import { ChevronDownIcon, ChevronUpIcon, InfoOutlineIcon, ArrowForwardIcon } from '@chakra-ui/icons'

import { mainnet } from 'viem/chains'

import { CHAIN_NETWORK_CONFIG } from '../../constants/chainNetworkConfig'
import StakingVaultABI from '../../constants/abis/StakingVault.json'
import { formatBigNumber, formatStringToNumber } from '../../utils'
import ERC20ABI from '../../constants/abis/ERC20.json'
import NetworkFee from '../NetworkFee/NetworkFee'
import sUSN from '../../assets/tokens/sUSN.svg'
import USN from '../../assets/tokens/USN.svg'
import { DAPP_CONFIG } from '../../constants'
import { TSTAKE_SCREEN } from '../../types'
import SUSNPrice from '../SUSNPrice'
import USNPrice from '../USNPrice'

interface IProps {
  usnsAmount: string
  setUSNAmount: (val: string) => void
  setUSNsAmount: (val: string) => void
  setScreen: (screen: TSTAKE_SCREEN) => void
}

const InputAndApproveUnStake = (props: IProps) => {
  const [infoVisible, setToggleInfoVisibility] = useState<boolean>(true)

  const chainId = useChainId({ config: CHAIN_NETWORK_CONFIG })
  const account = useAccount({ config: CHAIN_NETWORK_CONFIG })

  const usnsBalance = useReadContract({
    abi: ERC20ABI,
    address: DAPP_CONFIG[chainId ?? mainnet.id].STAKING_VAULT_ADDRESS,
    functionName: 'balanceOf',
    args: [account.address ?? zeroAddress],
    config: CHAIN_NETWORK_CONFIG,
    query: {
      enabled: true,
      refetchOnWindowFocus: true,
      refetchOnReconnect: true,
      refetchOnMount: true,
      refetchIntervalInBackground: true,
      refetchInterval: DAPP_CONFIG[chainId ?? mainnet.id].DATA_REFRESH_INTERVAL,
    },
  })

  const usnOutputAmount = useReadContract({
    abi: StakingVaultABI,
    address: DAPP_CONFIG[chainId ?? mainnet.id].STAKING_VAULT_ADDRESS,
    functionName: 'previewRedeem',
    args: [parseUnits(props.usnsAmount ?? '0', 18)],
    config: CHAIN_NETWORK_CONFIG,
    query: {
      enabled: true,
      refetchOnWindowFocus: true,
      refetchOnReconnect: true,
      refetchOnMount: true,
      refetchIntervalInBackground: true,
      refetchInterval: DAPP_CONFIG[chainId ?? mainnet.id].DATA_REFRESH_INTERVAL,
    },
  })

  const isActionDisabled = useMemo(
    () =>
      !account ||
      !account.address ||
      !props.usnsAmount ||
      !Number(props.usnsAmount) ||
      parseUnits(props.usnsAmount ?? '0', 18) > ((usnsBalance.data as bigint) ?? BigInt(0)),
    [account, account.address, props, props.usnsAmount],
  )

  const formattedUSNsBalance = useMemo(
    () => formatBigNumber((usnsBalance.data as bigint) ?? BigInt(0), 18),
    [props, usnsBalance, formatBigNumber],
  )

  const formattedUSNOutputAmount = useMemo(
    () => formatBigNumber((usnOutputAmount.data as bigint) ?? BigInt(0), 18),
    [props, formatStringToNumber, usnOutputAmount],
  )

  useEffect(() => {
    props.setUSNAmount(formatBigNumber((usnOutputAmount.data as bigint) ?? BigInt(0), 18, false))
  }, [props, usnOutputAmount])

  const onChangeUSNsAmount = (event: React.FormEvent<HTMLInputElement>) => {
    const value: string = (event?.target as HTMLInputElement)?.value
    if (value === '') {
      props.setUSNsAmount('')
    }

    if (!value || !Number(value)) {
      return
    }

    props.setUSNsAmount(value)
  }

  const setUSNsAmount = (option: 'half' | 'max') => {
    const bigIntVal = (usnsBalance.data as bigint) ?? BigInt(0)
    const optionBigIntval = option === 'half' ? bigIntVal / BigInt(2) : bigIntVal > BigInt(0) ? bigIntVal : BigInt(0)
    const value = formatBigNumber(optionBigIntval, 18, false)
    props.setUSNsAmount(value)
  }

  return (
    <>
      <div className="w-full flex items-start justify-between rounded-[10px] p-3 gap-2 bg-stone-100">
        <InfoOutlineIcon
          height={'h-6'}
          width={'w-6'}
          className="h-6 w-6 text-stone-900 shrink-0"
          stroke={'text-stone-900'}
          color={'text-stone-900'}
          flexShrink={0}
        />
        <div className="flex flex-col items-start justify-center gap-[2px] shrink">
          <p className="font-Suisse-Intl font-[450] text-base text-stone-900 text-wrap">
            Unstake Request to be withdrawn in 7 days
          </p>
          {infoVisible && (
            <p className="font-Suisse-Intl font-[450] text-sm text-stone-500 text-wrap text-left">
              There is a 7-day cooling off period for unstaking sUSN. Your USN will be available to withdraw 7 days
              after you unstake your sUSN.
            </p>
          )}
        </div>
        {infoVisible ? (
          <ChevronUpIcon
            onClick={() => setToggleInfoVisibility(false)}
            height={'h-6'}
            width={'w-6'}
            className="h-6 w-6 text-stone-400 shrink-0 cursor-pointer"
            stroke={'text-stone-400'}
            color={'text-stone-400'}
            flexShrink={0}
          />
        ) : (
          <ChevronDownIcon
            onClick={() => setToggleInfoVisibility(true)}
            height={'h-6'}
            width={'w-6'}
            className="h-6 w-6 text-stone-400 shrink-0 cursor-pointer"
            stroke={'text-stone-400'}
            color={'text-stone-400'}
            flexShrink={0}
          />
        )}
      </div>

      <div className="w-full flex flex-col items-start justify-between gap-1">
        <p className="w-full font-Suisse-Intl font-[450] text-base leading-6 text-stone-500">
          Enter the sUSN amount you would like to unstake
        </p>
        <div className="w-full flex flex-col items-start justify-between gap-2">
          <div className="w-full flex rounded-lg border-[1px] p-4 gap-2 bg-stone-50 border-stone-200 items-center justify-between">
            <input
              name="unstake-amount"
              id="unstake-amount"
              type="text"
              className="outline-none font-Suisse-Intl font-[450] text-base leading-6 text-stone-900 bg-transaparent border-none grow border-2 border-stone-200 placeholder:text-stone-400"
              placeholder="Amount of sUSN to unstake"
              value={props.usnsAmount}
              onChange={onChangeUSNsAmount}
            />
            <div
              id="unstake-currency"
              className="cursor-pointer flex gap-[4px] rounded-[4px] py-[2px] px-[6px] items-center justify-between justify-self-end"
            >
              <div className="w-[18px] h-[18px] leading-6">
                <img src={sUSN} alt="Token image" className="w-full h-full" />
              </div>
              <p className="font-Suisse-Intl font-medium text-base leading-6 text-stone-600">sUSN</p>
            </div>
          </div>
          <div className="w-full flex gap-1 items-center justify-between">
            <p className="font-Suisse-Intl font-[450] text-sm text-stone-400 text-wrap max-w-[48%]">
              Available: {formattedUSNsBalance} USN
            </p>
            <div className="w-fit flex gap-1 items-center justify-between">
              <button
                onClick={() => setUSNsAmount('half')}
                className="rounded py-[2px] px-2 bg-stone-100 font-Suisse-Intl font-medium text-sm text-stone-500"
              >
                HALF
              </button>
              <button
                onClick={() => setUSNsAmount('max')}
                className="rounded py-[2px] px-2 bg-stone-100 font-Suisse-Intl font-medium text-sm text-stone-500"
              >
                MAX
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="w-full flex gap-1 justify-between items-center">
        <p className="font-Suisse-Intl font-[450] text-base text-stone-500 text-wrap w-auto justify-self-start">
          You will receive
        </p>
        <div className="flex rounded gap-1 items-center justify-end w-[48%]">
          <div className="shrink-0 w-6 h-6 rounded-[100px] max-w-auto">
            <img src={USN} className="w-full h-full" alt="sUSN" />
          </div>
          <p className="min-w-[45%] font-Suisse-Intl font-semibold text-lg text-stone-950 text-wrap justify-self-end text-right">
            {formattedUSNOutputAmount} USN
          </p>
        </div>
      </div>

      <hr className="w-full block border-[1px] border-stone-200" />

      <div className="w-full flex flex-col items-between justify-center gap-2">
        <SUSNPrice currency="USDC" />
        <USNPrice currency="USDC" />
        <NetworkFee action="unstake" />
      </div>

      <button
        onClick={() => props.setScreen('confirm')}
        disabled={isActionDisabled}
        className={`w-full rounded-[184px] py-3 px-[18px] ${isActionDisabled ? 'bg-indigo-400' : 'bg-indigo-600'} flex gap-[6px] items-center justify-center`}
      >
        <p
          className={`font-Suisse-Intl font-medium text-base ${isActionDisabled ? 'text-indigo-200' : 'text-stone-50'}`}
        >
          Next
        </p>
        <ArrowForwardIcon
          className={`w-6 h-6 ${isActionDisabled ? 'text-indigo-200' : 'text-stone-50'}`}
          height={'h-6'}
          width={'w-6'}
          stroke={isActionDisabled ? 'text-indigo-200' : 'text-stone-50'}
          color={isActionDisabled ? 'text-indigo-200' : 'text-stone-50'}
        />
      </button>
    </>
  )
}

export default InputAndApproveUnStake
