/* eslint-disable react/react-in-jsx-scope */
import { Table, Thead, Tbody, Tr, Th, Td, TableContainer } from '@chakra-ui/react'
import { ArrowForwardIcon, ExternalLinkIcon, InfoIcon } from '@chakra-ui/icons'
import { ConnectButton } from '@rainbow-me/rainbowkit'
import { useMemo } from 'react'

import moment from 'moment'

import { useAccount } from 'wagmi'

import { NavLink } from 'react-router-dom'

import noRecentTransactions from '../../assets/dashboard/noRecentTransactions.svg'
import { formatBigNumberUsingAbbrevations } from '../../utils'
import withdrawn from '../../assets/dashboard/withdrawn.svg'
import unstaked from '../../assets/dashboard/unstaked.svg'
import redeemed from '../../assets/dashboard/redeemed.svg'
import claimed from '../../assets/dashboard/claimed.svg'
import staked from '../../assets/dashboard/staked.svg'
import minted from '../../assets/dashboard/minted.svg'
import USDC from '../../assets/tokens/USDC.svg'
import USDT from '../../assets/tokens/USDT.svg'
import sUSN from '../../assets/tokens/sUSN.svg'
import { TUserActivityData } from '../../types'
import USN from '../../assets/tokens/USN.svg'

interface IProps {
  connected?: boolean
  transactions?: TUserActivityData['recent_transactions']
}

const RecentTransactions = ({ connected = false, transactions = [] }: IProps) => {
  const account = useAccount()

  const txns = useMemo(() => {
    return transactions
      .map((txn, i) => ({
        txnNo: i,
        action: txn.action,
        status: txn.status,
        timestamp: txn.timestamp,
        txnStatusCssClasses:
          txn.status.toLowerCase() === 'successfull' || txn.status.toLowerCase() === 'success'
            ? 'text-green-700 bg-green-200'
            : txn.status.toLowerCase() === 'in process' ||
                txn.status.toLowerCase() === 'in-process' ||
                txn.status.toLowerCase() === 'in_process'
              ? 'text-warning-950 bg-warning-200'
              : txn.status.toLowerCase() === 'fail' || txn.status.toLowerCase() === 'failed'
                ? 'text-red-700 bg-red-200'
                : '',
        tokenIn: txn.token_in.toLowerCase(),
        tokenInAmount: formatBigNumberUsingAbbrevations(
          BigInt(txn?.amount_in || txn.amount || '0'),
          txn.token_in.toLowerCase() === 'usdc' || txn.token_in.toLowerCase() === 'usdt' ? 6 : 18,
          true,
        ),
        tokenOutAmount: formatBigNumberUsingAbbrevations(
          BigInt(txn?.amount_out || txn.amount || '0'),
          txn.token_out.toLowerCase() === 'usdc' || txn.token_out.toLowerCase() === 'usdt' ? 6 : 18,
          true,
        ),
        tokenOut: txn.token_out.toLowerCase(),
        txnHash: txn.transaction_hash,
        tokenInIcon:
          txn.token_in.toLowerCase() === 'usn'
            ? USN
            : txn.token_in.toLowerCase() === 'susn'
              ? sUSN
              : txn.token_in.toLowerCase() === 'usdc'
                ? USDC
                : txn.token_in.toLowerCase() === 'usdt'
                  ? USDT
                  : USN,
        tokenOutIcon:
          txn.token_out.toLowerCase() === 'usn'
            ? USN
            : txn.token_out.toLowerCase() === 'susn'
              ? sUSN
              : txn.token_out.toLowerCase() === 'usdc'
                ? USDC
                : txn.token_out.toLowerCase() === 'usdt'
                  ? USDT
                  : USN,
        tokenInSymbol: txn.token_in.toUpperCase(),
        tokenOutSymbol: txn.token_out.toUpperCase(),
        actionIcon:
          txn.action.toLowerCase() === 'mint' || txn.action.toLowerCase() === 'minted'
            ? minted
            : txn.action.toLowerCase() === 'redeem' ||
                txn.action.toLowerCase() === 'redeemed' ||
                txn.action.toLowerCase() === 'transfer' ||
                txn.action.toLowerCase() === 'transferFrom'
              ? redeemed
              : txn.action.toLowerCase() === 'stake' || txn.action.toLowerCase() === 'staked'
                ? staked
                : txn.action.toLowerCase() === 'unstake' || txn.action.toLowerCase() === 'unstaked'
                  ? unstaked
                  : txn.action.toLowerCase() === 'claim' || txn.action.toLowerCase() === 'claimed'
                    ? claimed
                    : txn.action.toLowerCase() === 'withdraw' ||
                        txn.action.toLowerCase() === 'withdrawn' ||
                        txn.action.toLowerCase() === 'withdrew'
                      ? withdrawn
                      : redeemed,
      }))
      .reverse()
  }, [transactions])

  return (
    <div className="w-full h-auto flex flex-col gap-[7px] items-start justify-start md:min-h-[512px]">
      <div className="w-full flex gap-2 items-center justify-start">
        <p className="font-Suisse-Intl font-medium text-sm text-stone-600">RECENT TRANSACTIONS</p>
        <InfoIcon
          height={'h-6'}
          width={'w-6'}
          className="h-6 w-6 text-stone-400"
          stroke={'text-stone-400'}
          color={'text-stone-400'}
        />
      </div>

      {!connected ? (
        <div className="w-full flex flex-col rounded-xl border-[1px] p-10 gap-4 items-center justify-around bg-white border-stone-300 data-container">
          <div className="w-32 h-28">
            <img alt="No recent transactions" className="w-full h-full" src={noRecentTransactions} />
          </div>
          <p className="font-Suisse-Intl font-semiMedium text-xl text-stone-900 text-center text-wrap max-w-[50%]">
            We couldn&apos;t locate any transactions associated with your Wallet.
          </p>
          <ConnectButton.Custom>
            {({ account, chain, openConnectModal, mounted }) => {
              const connected = mounted && account && chain

              return (
                <div
                  {...(!mounted && {
                    'aria-hidden': true,
                    style: {
                      opacity: 0,
                      pointerEvents: 'none',
                      userSelect: 'none',
                    },
                  })}
                >
                  {(() => {
                    if (!connected) {
                      return (
                        <button
                          onClick={openConnectModal}
                          className="w-fit rounded-[184px] py-3 px-[18px] bg-indigo-600 flex gap-[6px] items-center justify-center"
                        >
                          <p className={`font-Suisse-Intl font-medium text-base text-stone-50`}>Connect Wallet</p>
                          <ArrowForwardIcon
                            className={`w-6 h-6 text-stone-50`}
                            height={'h-6'}
                            width={'w-6'}
                            stroke={'text-stone-50'}
                            color={'text-stone-50'}
                          />
                        </button>
                      )
                    }
                  })()}
                </div>
              )
            }}
          </ConnectButton.Custom>
        </div>
      ) : !txns || !txns?.length ? (
        <div className="w-full flex flex-col rounded-xl border-[1px] p-10 gap-4 items-center justify-around bg-white border-stone-300 data-container">
          <div className="w-32 h-28">
            <img alt="No recent transactions" className="w-full h-full" src={noRecentTransactions} />
          </div>
          <p className="font-Suisse-Intl font-semiMedium text-xl text-stone-900 text-center text-wrap max-w-[50%]">
            We couldn&apos;t locate any transactions associated with your Wallet.
          </p>
          <div className="w-fit flex items-center justify-center gap-2">
            <NavLink
              to={'/staking'}
              className={`w-fit rounded-[184px] py-3 px-[18px] bg-indigo-600 flex gap-[6px] items-center justify-center`}
            >
              <p className={`font-Suisse-Intl font-medium text-base text-stone-50`}>Mint USN</p>
              <ArrowForwardIcon
                className={`w-6 h-6 text-stone-50`}
                height={'h-6'}
                width={'w-6'}
                stroke={'text-stone-50'}
                color={'text-stone-50'}
              />
            </NavLink>
            <NavLink
              to={'/staking'}
              className={`w-fit rounded-[184px] py-3 px-[18px] bg-stone-100 border-stone-300 border-[1px] flex gap-[6px] items-center justify-center`}
            >
              <p className={`font-Suisse-Intl font-medium text-base text-stone-900`}>Stake sUSN</p>
              <ArrowForwardIcon
                className={`w-6 h-6 text-stone-900`}
                height={'h-6'}
                width={'w-6'}
                stroke={'text-stone-900'}
                color={'text-stone-900'}
              />
            </NavLink>
          </div>
        </div>
      ) : (
        <div className="w-full rounded-md bg-stone-50 table-container">
          <TableContainer className="rounded-md">
            <Table variant="simple">
              <Thead className="py-2 px-3 border-b-[1px] bg-stone-50 border-stone-200 text-stone-600">
                <Tr className="text-stone-600">
                  <Th className="text-stone-600">TX No.</Th>
                  <Th className="text-stone-600">ACTION</Th>
                  <Th className="text-stone-600">STATUS by</Th>
                  <Th className="text-stone-600">TOKEN IN</Th>
                  <Th className="text-stone-600">TOKEN OUT</Th>
                  <Th className="w-full flex items-center justify-end text-stone-600">TRANSACTION HASH</Th>
                </Tr>
              </Thead>
              <Tbody className="bg-white">
                {txns.map((txn, i) => (
                  <Tr key={txn?.txnHash ?? i}>
                    <Td className="border-b-[1px] py-2 px-4 gap-2 border-stone-200">{txn?.txnNo ?? i}</Td>
                    <Td className="border-b-[1px] py-2 px-4 border-stone-200">
                      <div className="w-fit flex items-start justify-center gap-2">
                        <div className="rounded-[100px] p-[6px] bg-stone-100">
                          <div className="w-6 h-6">
                            <img alt="Action" className="w-full h-full" src={txn?.actionIcon ?? redeemed} />
                          </div>
                        </div>
                        <div>
                          <p className="font-Suisse-Intl font-medium text-base text-stone-700 capitalize">
                            {txn?.action ?? 'Onchain action'}
                          </p>
                          <p className="font-Suisse-Intl font-[450] text-sm text-stone-500">
                            {moment(Date.parse(txn?.timestamp) ?? new Date()).fromNow()}
                          </p>
                        </div>
                      </div>
                    </Td>
                    <Td className="border-b-[1px] py-2 px-4 gap-2 border-stone-200">
                      <p
                        className={`w-fit font-Suisse-Intl font-medium text-sm text-green-700 bg-green-200 py-[2px] px-2 rounded-md ${txn?.txnStatusCssClasses ?? ''}`}
                      >
                        {txn?.status?.toUpperCase() ?? 'UNKNOWN'}
                      </p>
                    </Td>
                    <Td className="border-b-[1px] py-2 px-4 gap-2 border-stone-200">
                      <div className="w-fit flex items-center justify-center gap-1">
                        <div className="w-5 h-5">
                          <img src={txn?.tokenInIcon ?? USN} alt="sUSN" className="w-full h-full" />
                        </div>
                        <p className="font-Suisse-Intl font-medium text-base text-stone-700">
                          {txn?.tokenInAmount ?? '0.00'} {txn?.tokenInSymbol ?? 'USN'}
                        </p>
                      </div>
                      <p className="font-Suisse-Intl font-[450] text-sm text-stone-500">
                        ~${txn?.tokenInAmount ?? '0.00'}
                      </p>
                    </Td>
                    <Td className="border-b-[1px] py-2 px-4 gap-2 border-stone-200">
                      <div className="w-fit flex items-center justify-center gap-1">
                        <div className="w-5 h-5">
                          <img src={txn?.tokenOutIcon ?? USN} alt="USN" className="w-full h-full" />
                        </div>
                        <p className="font-Suisse-Intl font-medium text-base text-stone-700">
                          {txn?.tokenOutAmount ?? '0.00'} {txn?.tokenOutSymbol ?? 'USN'}
                        </p>
                      </div>
                      <p className="font-Suisse-Intl font-[450] text-sm text-stone-500">
                        ~${txn?.tokenOutAmount ?? '0.00'}
                      </p>
                    </Td>
                    <Td className="border-b-[1px] py-2 px-4 gap-2 justify-self-end">
                      {txn?.txnHash ? (
                        <a
                          href={`${account?.chain?.blockExplorers?.default.url}/tx/${txn?.txnHash}`}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <div className="w-full flex items-center justify-end gap-1">
                            <p className="font-Suisse-Intl font-[450] text-base text-stone-700">
                              {txn?.txnHash
                                ? `${txn?.txnHash.slice(0, 6)}...${txn?.txnHash.slice(txn?.txnHash.length - 6)}`
                                : '-'}
                            </p>
                            <ExternalLinkIcon
                              height={'h-4'}
                              width={'w-4'}
                              className="h-4 w-4 text-stone-400"
                              stroke={'text-stone-400'}
                              color={'text-stone-400'}
                            />
                          </div>
                        </a>
                      ) : (
                        <p className="font-Suisse-Intl font-[450] text-base text-stone-700 text-right">-</p>
                      )}
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </TableContainer>
        </div>
      )}
    </div>
  )
}

export default RecentTransactions
