/* eslint-disable react/react-in-jsx-scope */
import { useEffect, useState } from 'react'
import { Tooltip } from '@chakra-ui/react'

import { InfoIcon, ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons'

import { useAccount, useChainId } from 'wagmi'

import { mainnet } from 'viem/chains'

import { InputAndApproveMint, MintConfirm, MintSuccessful } from '../../components/Mint'
import { TCollateral, TMINT_SCREEN, TPENDING_REQUESTS } from '../../types'
import { CHAIN_NETWORK_CONFIG } from '../../constants/chainNetworkConfig'
import ProtocolInfo from '../../components/ProtocolInfo/ProtocolInfo'
import { DAPP_CONFIG } from '../../constants'
import { formatBigNumber } from '../../utils'

const MintingPage = () => {
  const account = useAccount()
  const chainId = useChainId({ config: CHAIN_NETWORK_CONFIG })

  const [screen, setScreen] = useState<TMINT_SCREEN>('input')
  const [collateralAmount, setCollateralAmount] = useState<string>('')
  const [isMintingInfoOpen, setIsMintingInfoOpen] = useState<boolean>(true)
  const [selectedCollateral, setSelectedCollateral] = useState<TCollateral>(
    DAPP_CONFIG[chainId ?? mainnet.id].COLLATERALS[0],
  )
  const [isInMintingQueue, setIsInMintingQueue] = useState<{
    isInQueue: boolean
    noOfRequests: number
    amount: string
  }>({
    isInQueue: false,
    noOfRequests: 0,
    amount: '0',
  })

  const fetchIsInMintingQueue = async () => {
    await fetch(`${process.env.REACT_APP_BASE_API_URL ?? ''}/api/v1/pending-requests/${account.address}`)
      .then(
        (res) => {
          if (res.ok) {
            return res.json()
          }

          throw new Error('Failed to fetch protocol metrics')
        },
        (err) => {
          throw new Error(err)
        },
      )
      .then((json) => json as TPENDING_REQUESTS)
      .then((requests) => {
        const amountsInBigint = requests?.pending_minting?.map((minting) => BigInt(minting?.order?.usnAmount ?? '0'))
        const amount = amountsInBigint.reduce((a: bigint, b: bigint) => a + b, BigInt(0))
        setIsInMintingQueue({
          isInQueue: requests?.pending_minting?.length > 0,
          noOfRequests: requests?.pending_minting?.length ?? 0,
          amount: formatBigNumber(amount, 18, true) ?? '0.00',
        })
        return
      })
      .catch((err) => {
        setIsInMintingQueue({
          isInQueue: false,
          noOfRequests: 0,
          amount: '0.00',
        })
        console.error('Failed to fetch protocol metrics', err)
        return
      })
  }

  useEffect(() => {
    fetchIsInMintingQueue()
  })

  return (
    <div className="h-fit flex flex-col md:grid md:grid-cols-2 gap-6 py-10 px-4 md:px-20 md:py-32 items-center justify-start md:items-start md:justify-center md:justify-items-center">
      <ProtocolInfo page="Minting" />

      <div className="w-full md:w-[73%] max-w-full rounded-xl border-[1px] border-stone-300 bg-white mint-container-shadow">
        <div className="flex flex-col py-4 px-3 md:p-6 gap-6 ">
          {screen === 'input' ? (
            <>
              <div className="w-fit flex gap-[2px] p-[2px] rounded-xl bg-stone-100 items-center justify-between">
                <button className="w-fit rounded-[10px] py-2 px-4 mint-tab-shadow bg-[#FFFFFF] font-Suisse-Intl font-medium text-base leading-6 text-selected-tab-text">
                  Mint
                </button>
                <button className="w-fit rounded-[10px] py-2 px-4 flex justify-between items-center gap-1">
                  <p className="font-Suisse-Intl font-medium text-base leading-6 text-unselected-tab-text">Redeem</p>
                  <Tooltip
                    hasArrow={true}
                    label="Redeeming will be permitted upon Mainnet launch."
                    bg="bg-stone-50"
                    color="text-stone-900"
                    placement="top"
                    borderRadius={'rounded-lg'}
                    paddingX={'px-3'}
                    paddingY={'py-[6px]'}
                    className="bg-stone-50 text-stone-900 rounded-lg py-[6px] px-3 w-36 h-20"
                  >
                    <InfoIcon className="w-5 h-5 text-stone-400" color="text-stone-400" height={'h-5'} width={'w-5'} />
                  </Tooltip>
                </button>
              </div>
              <InputAndApproveMint
                setScreen={setScreen}
                collateralAmount={collateralAmount}
                selectedCollateral={selectedCollateral}
                selectCollateral={setSelectedCollateral}
                setCollateralAmount={setCollateralAmount}
              />
            </>
          ) : screen === 'confirm' ? (
            <MintConfirm
              setScreen={setScreen}
              collateralAmount={collateralAmount}
              selectedCollateral={selectedCollateral}
              selectCollateral={setSelectedCollateral}
              setCollateralAmount={setCollateralAmount}
            />
          ) : (
            <MintSuccessful
              setScreen={setScreen}
              collateralAmount={collateralAmount}
              selectedCollateral={selectedCollateral}
              selectCollateral={setSelectedCollateral}
              setCollateralAmount={setCollateralAmount}
            />
          )}
        </div>

        {isInMintingQueue.isInQueue && (
          <div className="flex flex-col items-start justify-between gap-2 py-4 px-6 bg-stone-50 border-t-[1px] border-stone-200  mint-container-shadow rounded-b-xl border-b-0">
            <div className="w-full flex items-center justify-between gap-2">
              <p className="font-Suisse-Intl font-[450] text-lg text-stone-900">
                {isInMintingQueue.noOfRequests} requests to mint {isInMintingQueue.amount} USN submitted
              </p>
              {isMintingInfoOpen ? (
                <ChevronUpIcon
                  onClick={() => setIsMintingInfoOpen(false)}
                  height={'h-6'}
                  width={'w-6'}
                  className="h-6 w-6 text-stone-400"
                  stroke={'text-stone-400'}
                  color={'text-stone-400'}
                />
              ) : (
                <ChevronDownIcon
                  onClick={() => setIsMintingInfoOpen(true)}
                  height={'h-6'}
                  width={'w-6'}
                  className="h-6 w-6 text-stone-400"
                  stroke={'text-stone-400'}
                  color={'text-stone-400'}
                />
              )}
            </div>
            {isMintingInfoOpen && (
              <>
                <p className="w-fit max-w-full font-Suisse-Intl font-[450] text-base text-stone-500 text-left">
                  We are reviewing each of your minting requests. We aim to respond to all the mint requests within
                  24hrs of their creation. Thank you for your patience.
                </p>
              </>
            )}
          </div>
        )}
      </div>
    </div>
  )
}

export default MintingPage
