/* eslint-disable react/react-in-jsx-scope */
import { useAccount, useChainId, useReadContract, useWriteContract } from 'wagmi'
import { maxUint256, parseUnits, zeroAddress } from 'viem'
import { useMemo, useState } from 'react'

import { ChevronDownIcon, ChevronUpIcon, ArrowForwardIcon, SpinnerIcon } from '@chakra-ui/icons'

import { waitForTransactionReceipt } from '@wagmi/core'

import { Menu, MenuItem, MenuList, MenuButton } from '@chakra-ui/react'

import { mainnet } from 'viem/chains'

import { CHAIN_NETWORK_CONFIG } from '../../constants/chainNetworkConfig'
import { formatBigNumber, formatStringToNumber } from '../../utils'
import { ETxnStatus, TCollateral, TMINT_SCREEN } from '../../types'
import ERC20ABI from '../../constants/abis/ERC20.json'
import NetworkFee from '../NetworkFee/NetworkFee'
import USDC from '../../assets/tokens/USDC.svg'
import USDT from '../../assets/tokens/USDT.svg'
import USN from '../../assets/tokens/USN.svg'
import { DAPP_CONFIG } from '../../constants'
import BalanceError from '../BalanceError/'
import USNPrice from '../USNPrice/'

interface IProps {
  collateralAmount: string
  selectedCollateral: TCollateral
  setScreen: (screen: TMINT_SCREEN) => void
  setCollateralAmount: (value: string) => void
  selectCollateral: (colateral: TCollateral) => void
}

const InputAndApproveMint = (props: IProps) => {
  const [approveTxnStatus, setApproveTxnStatus] = useState<ETxnStatus>(ETxnStatus.IDLE)
  const [approveButtonText, setApproveButtonText] = useState<string>('Approve')

  const chainId = useChainId({ config: CHAIN_NETWORK_CONFIG })
  const account = useAccount({ config: CHAIN_NETWORK_CONFIG })
  const { writeContract } = useWriteContract({ config: CHAIN_NETWORK_CONFIG })

  const collateralBalance = useReadContract({
    abi: ERC20ABI,
    address: props.selectedCollateral.contractAddress,
    functionName: 'balanceOf',
    args: [account.address ?? zeroAddress],
    config: CHAIN_NETWORK_CONFIG,
    query: {
      enabled: true,
      refetchOnWindowFocus: true,
      refetchOnReconnect: true,
      refetchOnMount: true,
      refetchIntervalInBackground: true,
      refetchInterval: DAPP_CONFIG[chainId ?? mainnet.id].DATA_REFRESH_INTERVAL,
    },
  })

  const collateralAllowance = useReadContract({
    abi: ERC20ABI,
    address: props.selectedCollateral.contractAddress,
    functionName: 'allowance',
    args: [account.address ?? zeroAddress, DAPP_CONFIG[chainId ?? mainnet.id].MINT_HANDLER_ADDRESS],
    config: CHAIN_NETWORK_CONFIG,
    query: {
      enabled: true,
      refetchOnWindowFocus: true,
      refetchOnReconnect: true,
      refetchOnMount: true,
      refetchIntervalInBackground: true,
      refetchInterval: DAPP_CONFIG[chainId ?? mainnet.id].DATA_REFRESH_INTERVAL,
    },
  })

  const isInputGtBalance = useMemo(() => {
    return (
      parseUnits(props.collateralAmount ?? '0', props.selectedCollateral.decimals) >
      ((collateralBalance.data as bigint) ?? BigInt(0))
    )
  }, [account, account.address, props, props.collateralAmount])

  const isActionDisabled = useMemo(
    () =>
      !account || !account.address || !props.collateralAmount || !Number(props.collateralAmount) || isInputGtBalance,
    [account, account.address, props, props.collateralAmount, isInputGtBalance],
  )

  const isCollateralApprovalNeeded = useMemo(() => {
    return (
      ((collateralAllowance.data as bigint) ?? BigInt(0)) <
      parseUnits(props.collateralAmount ?? '0', props.selectedCollateral.decimals)
    )
  }, [props, props.collateralAmount])

  const formattedCollateralBalance = useMemo(
    () => formatBigNumber((collateralBalance.data as bigint) ?? BigInt(0), props.selectedCollateral.decimals),
    [props, collateralBalance, formatBigNumber],
  )

  const formattedUSNOutputAmount = useMemo(
    () => formatStringToNumber(Number(props.collateralAmount) ? props.collateralAmount : '', true),
    [props, formatStringToNumber],
  )

  const onChangeCollatealAmount = (event: React.FormEvent<HTMLInputElement>) => {
    const value: string = (event?.target as HTMLInputElement)?.value
    if (value === '') {
      props.setCollateralAmount('')
    }

    if (!value || !Number(value)) {
      return
    }

    props.setCollateralAmount(value)
  }

  const setCollateralAmount = (option: 'half' | 'max') => {
    const bigIntVal = (collateralBalance.data as bigint) ?? BigInt(0)
    const optionBigIntval = option === 'half' ? bigIntVal / BigInt(2) : bigIntVal > BigInt(0) ? bigIntVal : BigInt(0)
    const value = formatBigNumber(optionBigIntval, props.selectedCollateral.decimals, false)
    props.setCollateralAmount(value)
  }

  const approveCollateral = async () => {
    if (!account.isConnected) {
      setApproveTxnStatus(ETxnStatus.TXN_FAILURE)
      setApproveButtonText('Try again')
      return
    }

    setApproveTxnStatus(ETxnStatus.PROCESSING)

    setApproveButtonText('Approving...')

    writeContract(
      {
        abi: ERC20ABI,
        address: props.selectedCollateral.contractAddress,
        functionName: 'approve',
        args: [DAPP_CONFIG[chainId ?? mainnet.id].MINT_HANDLER_ADDRESS ?? zeroAddress, maxUint256],
      },
      {
        onError: () => {
          setApproveTxnStatus(ETxnStatus.TXN_FAILURE)
          setApproveButtonText('Try again')
          setTimeout(() => {
            setApproveTxnStatus(ETxnStatus.IDLE)
            setApproveButtonText('Approve')
          }, 30 * 1000)
        },
        onSuccess: () => {
          setApproveTxnStatus(ETxnStatus.TXN_SUBMITTED_ON_CHAIN)
          setApproveButtonText('Approving...')
        },
        onSettled: async (data) => {
          const receipt = await waitForTransactionReceipt(CHAIN_NETWORK_CONFIG, { hash: data as `0x${string}` })
          if (receipt.status === 'success') {
            collateralAllowance.refetch()
            setApproveTxnStatus(ETxnStatus.TXN_SUCCESS)
            setApproveButtonText('Approved')
          } else {
            setApproveTxnStatus(ETxnStatus.TXN_FAILURE)
            setApproveButtonText('Try again')
            setTimeout(() => {
              setApproveTxnStatus(ETxnStatus.IDLE)
              setApproveButtonText('Approve')
            }, 30 * 1000)
          }
        },
      },
    )
  }

  return (
    <>
      <div className="w-full flex flex-col items-start justify-between gap-1">
        <p className="w-full font-Suisse-Intl font-[450] text-base leading-6 text-stone-500">
          Enter the amount you would like to mint
        </p>
        <div className="w-full flex flex-col items-start justify-between gap-2">
          <div
            className={`w-full flex rounded-lg border-[1px] p-4 gap-2 bg-stone-50 items-center justify-between ${isInputGtBalance ? 'border-red-500' : 'border-stone-200'}`}
          >
            <input
              name="mint-amount"
              id="mint-amount"
              type="text"
              className="outline-none font-Suisse-Intl font-[450] text-base leading-6 text-stone-900 placeholder:text-stone-400 bg-transaparent border-none grow border-2 border-stone-200"
              placeholder="Amount to Mint"
              value={props.collateralAmount}
              onChange={onChangeCollatealAmount}
            />
            <Menu placement="bottom">
              {({ isOpen }) => (
                <>
                  <MenuButton padding={0} margin={0}>
                    <div
                      id="mint-currency"
                      className="cursor-pointer flex gap-[2px] rounded-[100px] border-[1px] py-[2px] px-[6px] bg-white border-stone-200 items-center justify-between justify-self-end"
                    >
                      <div className="w-[18px] h-[18px] leading-6">
                        <img
                          src={props.selectedCollateral.symbol === 'USDC' ? USDC : USDT}
                          alt="Token image"
                          className="w-full h-full"
                          onError={() => import(`../../assets/tokens/${props.selectedCollateral.assetsIconName}`)}
                        />
                      </div>
                      <p className="font-Suisse-Intl font-medium text-base leading-6 text-stone-600">
                        {props.selectedCollateral.name}
                      </p>
                      {isOpen ? (
                        <ChevronUpIcon
                          className="w-[18px] h-[18px] leading-6 text-stone-400"
                          height={'h-[18px]'}
                          width={'w-[18px]'}
                          color={'text-stone-400'}
                        />
                      ) : (
                        <ChevronDownIcon
                          className="w-[18px] h-[18px] leading-6 text-stone-400"
                          height={'h-[18px]'}
                          width={'w-[18px]'}
                          color={'text-stone-400'}
                        />
                      )}
                    </div>
                  </MenuButton>
                  <MenuList background={'bg-transparent'} borderWidth={0} padding={0} margin={0}>
                    <div className="rounded-lg border-[1px] p-2 bg-white border-stone-200 w-fit">
                      {DAPP_CONFIG[chainId ?? mainnet.id].COLLATERALS.map((collateral: TCollateral) => (
                        <MenuItem
                          onClick={() => props.selectCollateral(collateral)}
                          key={collateral.contractAddress}
                          padding={0}
                          margin={0}
                          background={'bg-transparent'}
                          borderWidth={0}
                        >
                          <div
                            id="mint-currency-item"
                            className="w-28 cursor-pointer flex gap-2 p-2 items-center justify-start rounded-[4px] hover:bg-stone-100"
                          >
                            <div className="w-[18px] h-[18px]">
                              <img
                                src={collateral.symbol === 'USDC' ? USDC : USDT}
                                alt="Token image"
                                className="w-full h-full"
                                onError={() => import(`../../assets/${collateral.assetsIconName}`)}
                              />
                            </div>
                            <p className="grow font-Suisse-Intl font-medium text-base leading-6 text-stone-600 text-left">
                              {collateral.name}
                            </p>
                          </div>
                        </MenuItem>
                      ))}
                    </div>
                  </MenuList>
                </>
              )}
            </Menu>
          </div>
          <div className="w-full flex gap-1 items-center justify-between">
            <p className="font-Suisse-Intl font-[450] text-sm text-stone-400 text-wrap max-w-[48%]">
              Available: {formattedCollateralBalance} {props.selectedCollateral.symbol}
            </p>
            <div className="w-fit flex gap-1 items-center justify-between">
              <button
                onClick={() => setCollateralAmount('half')}
                className="rounded py-[2px] px-2 bg-stone-100 font-Suisse-Intl font-medium text-sm text-stone-500"
              >
                HALF
              </button>
              <button
                onClick={() => setCollateralAmount('max')}
                className="rounded py-[2px] px-2 bg-stone-100 font-Suisse-Intl font-medium text-sm text-stone-500"
              >
                MAX
              </button>
            </div>
          </div>
        </div>
      </div>

      {isInputGtBalance && (
        <BalanceError
          header={'Insufficient wallet balance'}
          body={'Add USN to your wallet or use a wallet that already has USN'}
        />
      )}

      <div className="w-full flex gap-1 justify-between items-center">
        <p className="font-Suisse-Intl font-[450] text-base text-stone-500 text-wrap w-auto justify-self-start">
          You will receive
        </p>
        <div className="flex rounded gap-1 items-start justify-end w-[48%]">
          <div className="shrink-0 w-6 h-6 rounded-[100px] max-w-auto">
            <img src={USN} className="w-full h-full" alt="USN" />
          </div>
          <p className="min-w-[45%] font-Suisse-Intl font-semibold text-lg text-stone-950 text-wrap justify-self-end text-right">
            {formattedUSNOutputAmount} $USN
          </p>
        </div>
      </div>

      <hr className="w-full block border-[1px] border-stone-200" />

      <div className="w-full flex flex-col items-between justify-center gap-2">
        <USNPrice currency={props.selectedCollateral.symbol} />
        <NetworkFee action="mint" />
      </div>

      <button
        onClick={isCollateralApprovalNeeded ? approveCollateral : () => props.setScreen('confirm')}
        disabled={
          isActionDisabled ||
          approveTxnStatus === ETxnStatus.PROCESSING ||
          approveTxnStatus === ETxnStatus.TXN_SUBMITTED_ON_CHAIN
        }
        className={`w-full rounded-[184px] py-3 px-[18px] ${isActionDisabled ? 'bg-indigo-400' : 'bg-indigo-600'} flex gap-[6px] items-center justify-center ${approveTxnStatus === ETxnStatus.PROCESSING || approveTxnStatus === ETxnStatus.TXN_SUBMITTED_ON_CHAIN ? 'animate-pulse' : ''}`}
      >
        {(approveTxnStatus === ETxnStatus.PROCESSING || approveTxnStatus === ETxnStatus.TXN_SUBMITTED_ON_CHAIN) && (
          <SpinnerIcon
            className={`w-6 h-6 ${isActionDisabled ? 'text-indigo-200' : 'text-stone-50'} animate-spin`}
            height={'h-6'}
            width={'w-6'}
            stroke={isActionDisabled ? 'text-indigo-200' : 'text-stone-50'}
            color={isActionDisabled ? 'text-indigo-200' : 'text-stone-50'}
          />
        )}
        <p
          className={`font-Suisse-Intl font-medium text-base ${isActionDisabled ? 'text-indigo-200' : 'text-stone-50'}`}
        >
          {isCollateralApprovalNeeded ? approveButtonText : 'Next'}
        </p>
        {approveTxnStatus !== ETxnStatus.PROCESSING && approveTxnStatus !== ETxnStatus.TXN_SUBMITTED_ON_CHAIN && (
          <ArrowForwardIcon
            className={`w-6 h-6 ${isActionDisabled ? 'text-indigo-200' : 'text-stone-50'}`}
            height={'h-6'}
            width={'w-6'}
            stroke={isActionDisabled ? 'text-indigo-200' : 'text-stone-50'}
            color={isActionDisabled ? 'text-indigo-200' : 'text-stone-50'}
          />
        )}
      </button>
    </>
  )
}

export default InputAndApproveMint
