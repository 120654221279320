/* eslint-disable react/react-in-jsx-scope */
import { useAccount, useChainId, useReadContract, useSignTypedData } from 'wagmi'
import { parseUnits, zeroAddress } from 'viem'
import { useMemo, useState } from 'react'

import { CheckIcon, SpinnerIcon, ArrowBackIcon } from '@chakra-ui/icons'

import { mainnet } from 'viem/chains'

import { EApiCallStatus, TCollateral, TMINT_SCREEN, TMintOrder } from '../../types'
import { CHAIN_NETWORK_CONFIG } from '../../constants/chainNetworkConfig'
import ERC20ABI from '../../constants/abis/ERC20.json'
import { formatStringToNumber } from '../../utils'
import NetworkFee from '../NetworkFee/NetworkFee'
import USDC from '../../assets/tokens/USDC.svg'
import USDT from '../../assets/tokens/USDT.svg'
import USN from '../../assets/tokens/USN.svg'
import { DAPP_CONFIG } from '../../constants'
import USNPrice from '../USNPrice'

interface IProps {
  collateralAmount: string
  selectedCollateral: TCollateral
  setScreen: (screen: TMINT_SCREEN) => void
  setCollateralAmount: (value: string) => void
  selectCollateral: (colateral: TCollateral) => void
}

const MintConfirm = (props: IProps) => {
  const chainId = useChainId({ config: CHAIN_NETWORK_CONFIG })
  const [confirmButtonText, setConfirmButtonText] = useState<string>('Confirm')
  const [mintStatus, setMintStatus] = useState<EApiCallStatus>(EApiCallStatus.IDLE)

  const account = useAccount({ config: CHAIN_NETWORK_CONFIG })
  const { signTypedData } = useSignTypedData({ config: CHAIN_NETWORK_CONFIG })

  const collateralBalance = useReadContract({
    abi: ERC20ABI,
    address: props.selectedCollateral.contractAddress,
    functionName: 'balanceOf',
    args: [account.address ?? zeroAddress],
    config: CHAIN_NETWORK_CONFIG,
    query: {
      enabled: true,
      refetchOnWindowFocus: true,
      refetchOnReconnect: true,
      refetchOnMount: true,
      refetchIntervalInBackground: true,
      refetchInterval: DAPP_CONFIG[chainId ?? mainnet.id].DATA_REFRESH_INTERVAL,
    },
  })

  const isActionDisabled = useMemo(
    () =>
      !account ||
      !account.address ||
      !props.collateralAmount ||
      !Number(props.collateralAmount) ||
      parseUnits(props.collateralAmount ?? '0', props.selectedCollateral.decimals) >
        ((collateralBalance.data as bigint) ?? BigInt(0)),
    [account, account.address, props, props.collateralAmount],
  )

  const formattedUSNOutputAmount = useMemo(
    () => formatStringToNumber(Number(props.collateralAmount) ? props.collateralAmount : ''),
    [props, formatStringToNumber],
  )

  const sendMintReq = async (order: TMintOrder, signature: string) => {
    await fetch(`${process.env.REACT_APP_BASE_API_URL ?? ''}/api/mint-usn`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        ...order,
        collateralAmount: parseUnits(props.collateralAmount ?? '0', props.selectedCollateral.decimals).toString(),
        usnAmount: parseUnits(props.collateralAmount ?? '0', 18).toString(),
        signature,
      }),
    })
      .then(
        (res) => {
          if (res.ok && res.status === 200) {
            setMintStatus(EApiCallStatus.API_CALL_SUCCESS)
            setConfirmButtonText('Confirmed')
            props.setScreen('success')
            return
          }
          throw new Error('Error')
        },
        (err) => {
          throw new Error('Error' + err?.message)
        },
      )
      .catch((err: Error) => {
        console.error('Error minting from MINT API:', err)
        setMintStatus(EApiCallStatus.API_CALL_FAILURE)
        setConfirmButtonText('Try again')
        setTimeout(() => {
          setMintStatus(EApiCallStatus.IDLE)
          setConfirmButtonText('Confirm')
        }, 30 * 1000)
      })
  }

  const signMintData = () => {
    if (!account.isConnected) {
      setMintStatus(EApiCallStatus.API_CALL_FAILURE)
      setConfirmButtonText('Try again')
      return
    }

    setMintStatus(EApiCallStatus.PROCESSING)
    setConfirmButtonText('Confirming...')

    const domain = {
      name: 'MinterHandler',
      version: '1',
      chainId: account.chainId,
      verifyingContract: DAPP_CONFIG[chainId ?? mainnet.id].MINT_HANDLER_ADDRESS as `0x${string}`,
    }

    const types = {
      Order: [
        { name: 'message', type: 'string' },
        { name: 'user', type: 'address' },
        { name: 'collateralAddress', type: 'address' },
        { name: 'collateralAmount', type: 'uint256' },
        { name: 'usnAmount', type: 'uint256' },
        { name: 'expiry', type: 'uint256' },
        { name: 'nonce', type: 'uint256' },
      ],
    }

    const order: TMintOrder = {
      message: `I, the undersigned, hereby agree to mint ${props.collateralAmount} USN against ${props.collateralAmount} ${props.selectedCollateral.symbol}.

      I acknowledge that:

1. This transaction is irreversible once confirmed.
2. I am subject to the protocol's terms and conditions.
3. The value of USN and ${props.selectedCollateral.symbol} may fluctuate.
4. I assume all risks associated with this transaction.
5. Neither the developers nor the protocol shall be held liable for any losses incurred.
6. I am responsible for ensuring compliance with all applicable laws and regulations.

By proceeding, I confirm that I have read, understood, and agree to the above terms.`,
      user: account.address as `${'0x'}string`,
      collateralAmount: parseUnits(props.collateralAmount ?? '0', props.selectedCollateral.decimals),
      usnAmount: parseUnits(props.collateralAmount ?? '0', 18),
      nonce: Math.floor(Math.random() * 100000000000),
      expiry: Math.floor(Date.now() / 1000) + 3600,
      collateralAddress: props.selectedCollateral.contractAddress as `${'0x'}string`,
    }

    signTypedData(
      {
        domain,
        types,
        message: order,
        primaryType: 'Order',
      },
      {
        onError: () => {
          setMintStatus(EApiCallStatus.API_CALL_FAILURE)
          setConfirmButtonText('Try again')
          setTimeout(() => {
            setMintStatus(EApiCallStatus.IDLE)
            setConfirmButtonText('Confirm')
          }, 30 * 1000)
        },
        onSuccess: (data) => {
          setConfirmButtonText('Confirming...')
          sendMintReq(order, data)
        },
      },
    )
  }

  return (
    <>
      <button
        className="w-fit flex items-start justify-between rounded-[4px] gap-1"
        onClick={() => props.setScreen('input')}
      >
        <ArrowBackIcon
          className={`w-6 h-6 text-stone-400`}
          height={'h-6'}
          width={'w-6'}
          stroke={'text-stone-400'}
          color={'text-stone-400'}
        />
        <p className="font-Suisse-Intl font-[450] text-base text-stone-500">BACK</p>
      </button>

      <p className="font-Louize font-normal text-4xl tracking-tighter text-stone-900">
        Confirm your USN minting <br /> transaction
      </p>

      <div className="w-full rounded-xl p-2 bg-stone-100">
        <div className="w-full h-full flex flex-col md:flex-row items-center justify-between gap-2 md:items-stretch">
          <div className="grow w-full md:max-w-[49%] rounded-md bg-white gap-2 py-5 px-[51px] flex flex-col items-center justify-center flex-wrap">
            <p className="font-Suisse-Intl font-[450] text-base text-stone-500 text-wrap text-center">Minting</p>
            <div className="w-full flex items-center justify-center rounded gap-1 flex-wrap">
              <div className="w-6 h-6 shrink-0 max-w-[48%]">
                <img src={USN} className="w-full h-full" alt="USN" />
              </div>
              <p className="shrink font-Suisse-Intl font-semibold text-2xl text-stone-900 text-wrap text-center max-w-full">
                {formattedUSNOutputAmount} USN
              </p>
            </div>
          </div>

          <div className="grow w-full md:max-w-[49%] rounded-md bg-white gap-2 py-5 px-[51px] flex flex-col items-center justify-center flex-wrap">
            <p className="font-Suisse-Intl font-[450] text-base text-stone-500 text-wrap text-center">For</p>
            <div className="w-full flex items-center justify-center rounded gap-1 flex-wrap">
              <div className="w-6 h-6 shrink-0">
                <img
                  src={
                    props.selectedCollateral.symbol.toLowerCase() === 'usdc'
                      ? USDC
                      : props.selectedCollateral.symbol.toLowerCase() === 'usdt'
                        ? USDT
                        : props.selectedCollateral.iconPath
                  }
                  className="w-full h-full"
                  alt={props.selectedCollateral.symbol}
                />
              </div>
              <p className="shrink font-Suisse-Intl font-semibold text-2xl text-stone-900 text-wrap text-center max-w-full">
                {formattedUSNOutputAmount} {props.selectedCollateral.symbol}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="w-full flex flex-col items-between justify-center gap-2">
        <USNPrice currency={props.selectedCollateral.symbol} />

        <NetworkFee action="mint" />
      </div>

      <button
        onClick={signMintData}
        disabled={isActionDisabled || mintStatus === EApiCallStatus.PROCESSING}
        className={`w-full rounded-[184px] py-3 px-[18px] ${isActionDisabled ? 'bg-indigo-400' : 'bg-indigo-600'} flex gap-[6px] items-center justify-center ${mintStatus === EApiCallStatus.PROCESSING ? 'animate-pulse' : ''}`}
      >
        {mintStatus === EApiCallStatus.PROCESSING && (
          <SpinnerIcon
            className={`w-6 h-6 ${isActionDisabled ? 'text-indigo-200' : 'text-stone-50'} animate-spin`}
            height={'h-6'}
            width={'w-6'}
            stroke={isActionDisabled ? 'text-indigo-200' : 'text-stone-50'}
            color={isActionDisabled ? 'text-indigo-200' : 'text-stone-50'}
          />
        )}
        {mintStatus !== EApiCallStatus.PROCESSING && mintStatus !== EApiCallStatus.API_CALL_FAILURE && (
          <CheckIcon
            className={`w-6 h-6 ${isActionDisabled ? 'text-indigo-200' : 'text-stone-50'}`}
            height={'h-6'}
            width={'w-6'}
            stroke={isActionDisabled ? 'text-indigo-200' : 'text-stone-50'}
            color={isActionDisabled ? 'text-indigo-200' : 'text-stone-50'}
          />
        )}
        <p
          className={`font-Suisse-Intl font-medium text-base ${isActionDisabled ? 'text-indigo-200' : 'text-stone-50'}`}
        >
          {confirmButtonText}
        </p>
      </button>
    </>
  )
}

export default MintConfirm
