import { useCallback, useEffect, useState } from 'react'

import { mainnet } from 'viem/chains'
import { useChainId } from 'wagmi'

import { CHAIN_NETWORK_CONFIG } from '../constants/chainNetworkConfig'
import { GasTrackerResponse } from '../types'
import { DAPP_CONFIG } from '../constants'
import { formatBigNumber } from '../utils'

const useNetworkFee = (action: string) => {
  const [networkFee, setNetworkFee] = useState<number>(0)

  const chainId = useChainId({ config: CHAIN_NETWORK_CONFIG })

  const fetchNetworkFee = useCallback(async () => {
    const networkGasData = await fetch(`${process.env.REACT_APP_BASE_API_URL ?? ''}/api/v1/gas-and-eth-price`)
      .then(
        (res) => {
          if (res.ok) {
            return res.json()
          }
          throw Error('Failed to get response from coingecko')
        },
        (err: Error) => {
          throw Error(err?.message)
        },
      )
      .then(
        (data) => {
          return data as GasTrackerResponse
        },
        (err: Error) => {
          throw Error(err?.message)
        },
      )
      .catch((err: Error) => {
        console.error('Failed fetching gas data from api', err)
        return {} as GasTrackerResponse
      })

    const gasLimitInNum = Number(DAPP_CONFIG[chainId].GAS_LIMITS[action] ?? 177251) || 177251
    const ethPriceInNum = Number(networkGasData?.ethPrice || 2610) || 2610
    const gasPriceInBigint = BigInt(networkGasData?.gasPrice ?? 13786322077) || BigInt(13786322077)
    const gasPriceInNum =
      Number(formatBigNumber(gasPriceInBigint, 18, false) ?? '0.000000013786322077') || 0.000000013786322077

    setNetworkFee(ethPriceInNum * gasPriceInNum * gasLimitInNum)
  }, [setNetworkFee])

  useEffect(() => {
    fetchNetworkFee().catch((err) => console.error(err.stack))

    const refreshBalance = setInterval(
      fetchNetworkFee,
      DAPP_CONFIG[chainId ?? mainnet.id].DATA_REFRESH_INTERVAL ?? 10000,
    )
    return () => clearInterval(refreshBalance)
  }, [setNetworkFee])

  return networkFee
}

export default useNetworkFee
