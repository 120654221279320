/* eslint-disable react/react-in-jsx-scope */
import { useMemo } from 'react'

import networkFeeIcon from '../../assets/networkFee.svg'
import useNetworkFee from '../../hooks/useNetworkFee'
import { formatStringToNumber } from '../../utils'

const NetworkFee = ({ action = 'mint' }: { action?: string }) => {
  const networkFee = useNetworkFee(action)

  const networkFeeFormatted = useMemo(() => {
    return formatStringToNumber((networkFee ?? '0').toString(), true)
  }, [])

  return (
    <div className="w-full flex gap-6 items-center justify-between">
      <div className="w-fit flex rounded gap-1 items-center justify-between">
        <div className="w-5 h-5 rounded-[100px]">
          <img src={networkFeeIcon} className="w-full h-full" alt="Network fee" />
        </div>
        <p className="font-Suisse-Intl font-[450] text-base text-stone-500">Network Fee</p>
      </div>
      <p className="font-Suisse-Intl font-semibold text-base text-stone-500">~ ${networkFeeFormatted}</p>
    </div>
  )
}

export default NetworkFee
