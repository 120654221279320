/* eslint-disable react/react-in-jsx-scope */
import USN from '../../assets/tokens/USN.svg'

const USNPrice = (props: { currency: string }) => {
  return (
    <div className="w-full flex gap-6 items-center justify-between">
      <div className="w-fit flex rounded gap-1 items-center justify-between">
        <div className="w-[18px] h-[18px] rounded-[100px]">
          <img src={USN} className="w-full h-full" alt="USN" />
        </div>
        <p className="font-Suisse-Intl font-[450] text-base text-stone-500">1 USN equal to</p>
      </div>
      <p className="font-Suisse-Intl font-semibold text-base text-stone-900">1 {props.currency}</p>
    </div>
  )
}

export default USNPrice
