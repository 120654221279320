/* eslint-disable react/react-in-jsx-scope */
import { InfoIcon, ChevronUpIcon, ChevronDownIcon, ExternalLinkIcon, ArrowForwardIcon } from '@chakra-ui/icons'
import { ConnectButton } from '@rainbow-me/rainbowkit'
import { useMemo, useState } from 'react'

import { NavLink } from 'react-router-dom'

import USDC from '../../assets/tokens/USDC.svg'
import USN from '../../assets/tokens/USN.svg'
import { formatBigNumber } from '../../utils'

interface IProps {
  minted?: string
  redeemed?: string
  connected?: boolean
}

const MintedAndRedeemed = ({ connected = false, minted = '0', redeemed = '0' }: IProps) => {
  const [mintInfoVisible, setMintInfoVisible] = useState<boolean>(true)

  const usnMinted = useMemo(() => {
    return formatBigNumber(BigInt(minted ?? 0), 18, true)
  }, [minted])

  const usnRedeemed = useMemo(() => {
    return formatBigNumber(BigInt(redeemed ?? 0), 18, true)
  }, [redeemed])

  const isEmpty = useMemo(() => {
    return !Number(minted) && !Number(redeemed)
  }, [usnMinted, usnRedeemed])

  return (
    <div className="w-full h-fit flex flex-col gap-[7px] items-start justify-start md:min-h-[165px]">
      <div className="w-full flex items-center justify-between">
        <div className="w-fit flex gap-2 items-center justify-center">
          <p className="font-Suisse-Intl font-medium text-sm text-stone-600">MINTED AND REDEEMED</p>
          <InfoIcon
            height={'h-6'}
            width={'w-6'}
            className="h-6 w-6 text-stone-400"
            stroke={'text-stone-400'}
            color={'text-stone-400'}
          />
        </div>
        <button className="w-fit h-fit" onClick={() => setMintInfoVisible((visibility) => !visibility)}>
          {mintInfoVisible ? (
            <ChevronUpIcon
              className="w-6 h-6 text-stone-400"
              color={'text-stone-400'}
              stroke={'text-stone-400'}
              height={'h-6'}
              width={'w-6'}
            />
          ) : (
            <ChevronDownIcon
              className="w-6 h-6 text-stone-400"
              color={'text-stone-400'}
              stroke={'text-stone-400'}
              height={'h-6'}
              width={'w-6'}
            />
          )}
        </button>
      </div>

      {mintInfoVisible &&
        (!connected ? (
          <div className="w-full flex flex-col rounded-xl border-[1px] p-10 gap-4 items-center justify-around bg-white border-stone-300 data-container">
            <p className="font-Suisse-Intl font-semiMedium text-xl text-stone-900 text-center text-wrap max-w-[50%]">
              It seems you haven&apos;t minted anything yet. Please mint a token to view your data here
            </p>
            <ConnectButton.Custom>
              {({ account, chain, openConnectModal, mounted }) => {
                const connected = mounted && account && chain

                return (
                  <div
                    {...(!mounted && {
                      'aria-hidden': true,
                      style: {
                        opacity: 0,
                        pointerEvents: 'none',
                        userSelect: 'none',
                      },
                    })}
                  >
                    {(() => {
                      if (!connected) {
                        return (
                          <button
                            onClick={openConnectModal}
                            className="w-fit rounded-[184px] py-3 px-[18px] bg-indigo-600 flex gap-[6px] items-center justify-center"
                          >
                            <p className={`font-Suisse-Intl font-medium text-base text-stone-50`}>Connect Wallet</p>
                            <ArrowForwardIcon
                              className={`w-6 h-6 text-stone-50`}
                              height={'h-6'}
                              width={'w-6'}
                              stroke={'text-stone-50'}
                              color={'text-stone-50'}
                            />
                          </button>
                        )
                      }
                    })()}
                  </div>
                )
              }}
            </ConnectButton.Custom>
          </div>
        ) : isEmpty ? (
          <div className="w-full flex flex-col rounded-xl border-[1px] p-10 gap-4 items-center justify-around bg-white border-stone-300 data-container">
            <p className="font-Suisse-Intl font-semiMedium text-xl text-stone-900 text-center text-wrap max-w-[50%]">
              It seems you haven&apos;t minted anything yet. Please mint a token to view your data here
            </p>
            <NavLink
              to="/minting"
              className="w-fit rounded-[184px] py-3 px-[18px] bg-indigo-600 flex gap-[6px] items-center justify-center"
            >
              <p className={`font-Suisse-Intl font-medium text-base text-stone-50`}>Mint USN</p>
              <ArrowForwardIcon
                className={`w-6 h-6 text-stone-50`}
                height={'h-6'}
                width={'w-6'}
                stroke={'text-stone-50'}
                color={'text-stone-50'}
              />
            </NavLink>
          </div>
        ) : (
          <div className="w-full gap-4 grid grid-rows-2 md:grid-rows-1 md:grid-cols-2 items-stretch">
            <div className="w-full flex flex-col rounded-xl border-[1px] p-4 gap-6 items-start justify-between bg-white border-stone-300 data-container">
              <p className="w-full font-Louize font-normal text-2xl tracking-tighter text-stone-400">Mint</p>
              <div className="w-full flex flex-col md:flex-row items-start md:items-center justify-between gap-4">
                <div className="flex flex-col items-start justify-center">
                  <p className="font-Suisse-Intl font-[450] text-sm text-stone-400">MINTED</p>
                  <div className="w-fit flex items-center justify-center gap-1">
                    <div className="w-6 h-6">
                      <img alt="USN" src={USN} className="w-full h-full" />
                    </div>
                    <p className="font-Suisse-Intl font-medium text-base text-stone-900">{usnMinted} USN</p>
                  </div>
                </div>
                <div className="w-full md:w-fit flex gap-3 items-center justify-center">
                  <button className="hidden md:flex gap-1 items-center justify-center rounded-[184px] py-2 px-3 grow">
                    <InfoIcon
                      height={'h-6'}
                      width={'w-6'}
                      className="h-6 w-6 text-stone-300"
                      stroke={'text-stone-300'}
                      color={'text-stone-300'}
                    />
                    <p className="font-Suisse-Intl font-medium text-base text-stone-400">Redeem</p>
                    <ExternalLinkIcon
                      height={'h-6'}
                      width={'w-6'}
                      className="h-6 w-6 text-stone-400"
                      stroke={'text-stone-400'}
                      color={'text-stone-400'}
                    />
                  </button>
                  <button className="font-medium bg-indigo-600 rounded-[184px] py-2 px-3 text-stone-50 grow">
                    Mint More
                  </button>
                  <button className="flex md:hidden gap-1 items-center justify-center rounded-[184px] py-2 px-3 grow">
                    <InfoIcon
                      height={'h-6'}
                      width={'w-6'}
                      className="h-6 w-6 text-stone-300"
                      stroke={'text-stone-300'}
                      color={'text-stone-300'}
                    />
                    <p className="font-Suisse-Intl font-medium text-base text-stone-400">Redeem</p>
                    <ExternalLinkIcon
                      height={'h-6'}
                      width={'w-6'}
                      className="h-6 w-6 text-stone-400"
                      stroke={'text-stone-400'}
                      color={'text-stone-400'}
                    />
                  </button>
                </div>
              </div>
            </div>

            <div className="w-full flex flex-col rounded-xl border-[1px] p-4 gap-6 items-start justify-between bg-white border-stone-300">
              <p className="w-full font-Louize font-normal text-2xl tracking-tighter text-stone-400">Redeem</p>
              <div className="w-full flex flex-col md:flex-row items-start md:items-center justify-between gap-4">
                <div className="flex flex-col items-start justify-center">
                  <p className="font-Suisse-Intl font-[450] text-sm text-stone-400">REDEEMED</p>
                  <div className="w-fit flex items-center justify-center gap-1">
                    <div className="w-6 h-6">
                      <img alt="USN" src={USN} className="w-full h-full" />
                    </div>
                    <p className="font-Suisse-Intl font-medium text-base text-stone-900">{usnRedeemed} USN</p>
                  </div>
                </div>
                <div className="flex flex-col items-start md:items-end justify-center">
                  <p className="font-Suisse-Intl font-[450] text-sm text-stone-400">RECEIVED</p>
                  <div className="w-fit flex items-center justify-center gap-1">
                    <div className="w-6 h-6">
                      <img alt="USDC" src={USDC} className="w-full h-full" />
                    </div>
                    <p className="font-Suisse-Intl font-medium text-base text-stone-900">{usnRedeemed} USDC</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
    </div>
  )
}

export default MintedAndRedeemed
