/* eslint-disable react/react-in-jsx-scope */
import { WarningTwoIcon } from '@chakra-ui/icons'

const BalanceError = (props: { header: string; body: string }) => {
  return (
    <div className="w-full flex items-start justify-between rounded-[10px] p-3 gap-2 bg-stone-100">
      <WarningTwoIcon
        flexShrink={0}
        width={'w-6'}
        height={'h-6'}
        stroke="text-red-700"
        color="text-red-700"
        className="w-6 h-6 shrink-0 text-red-700"
      />
      <div className="flex flex-col items-start justify-center gap-[2px] shrink">
        <p className="font-Suisse-Intl font-[450] text-base text-red-700 text-wrap">{props.header}</p>
        <p className="font-Suisse-Intl font-[450] text-sm text-stone-500 text-wrap">{props.body}</p>
      </div>
    </div>
  )
}

export default BalanceError
