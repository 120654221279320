/* eslint-disable react/react-in-jsx-scope */

import { ArrowUpIcon, InfoOutlineIcon } from '@chakra-ui/icons'
import { useMemo } from 'react'

import successIconGroup from '../../assets/success/successGroup.svg'
import sUSN from '../../assets/tokens/sUSN.svg'
import EmptyContainer from '../EmptyContainer'
import USN from '../../assets/tokens/USN.svg'
import { formatBigNumber } from '../../utils'

interface IProps {
  points?: string
  isEmpty?: boolean
  points24Hrs?: string
  usnHoldings?: string
  sUsnHoldings?: string
}

const Holdings = ({
  isEmpty = false,
  usnHoldings = '0',
  sUsnHoldings = '0',
  points = '0',
  points24Hrs = '0',
}: IProps) => {
  const usnHolding = useMemo(() => {
    return formatBigNumber(BigInt(usnHoldings ?? 0), 18, true)
  }, [usnHoldings])

  const susnHolding = useMemo(() => {
    return formatBigNumber(BigInt(sUsnHoldings ?? 0), 18, true)
  }, [sUsnHoldings])

  const pointsHolding = useMemo(() => {
    return (
      formatBigNumber(BigInt(points ?? 0), 18, true)
        ?.split('.')
        ?.at(0) ?? '0.00'
    )
  }, [points])

  const point24HrsHolding = useMemo(() => {
    return (
      formatBigNumber(BigInt(points24Hrs ?? 0), 18, true)
        ?.split('.')
        ?.at(0) ?? '0.00'
    )
  }, [points])

  return (
    <div className="w-full min-h-32 h-full md:w-[40%] md:max-w-[40%] flex flex-col items-center justify-center gap-3">
      <div className="w-full grid grid-rows-2 grid-cols-1 md:grid-rows-1 md:grid-cols-2 items-center justify-center gap-3">
        <div className="row-span-1 md:col-span-1 rounded-lg border-[1px] p-3 gap-1 bg-stone-50 border-stone-200 flex flex-col items-start justify-center">
          <p className="font-Suisse-Intl font-[450] text-base text-stone-500">USN HOLDINGS</p>
          <div className="flex items-center justify-start gap-1">
            <div className="w-6 h-6">
              <img alt="USN" className="w-full h-full" src={USN} />
            </div>
            <p className="font-Suisse-Intl font-semibold text-2xl text-stone-900">{isEmpty ? '0.00' : usnHolding}</p>
          </div>
          {/* {!isEmpty && <p className="font-Suisse-Intl font-[450] text-sm text-stone-400">since 23 Jun 2024</p>} */}
        </div>

        <div className="row-span-1 md:col-span-1 rounded-lg border-[1px] p-3 gap-1 bg-stone-50 border-stone-200 flex flex-col items-start justify-center">
          <p className="font-Suisse-Intl font-[450] text-base text-stone-500">sUSN HOLDINGS</p>
          <div className="flex items-center justify-start gap-1">
            <div className="w-6 h-6">
              <img alt="USN" className="w-full h-full" src={sUSN} />
            </div>
            <p className="font-Suisse-Intl font-semibold text-2xl text-stone-900">{isEmpty ? '0.00' : susnHolding}</p>
          </div>
          {/* {!isEmpty && <p className="font-Suisse-Intl font-[450] text-sm text-stone-400">since 23 Jun 2024</p>} */}
        </div>
      </div>

      <div className="w-full rounded-xl m-0 p-0 border-[1px] border-stone-300 data-container">
        <div className="relative h-fit w-full gap-1 p-3 overflow-hidden">
          <div className="w-full flex flex-col gap-3">
            <div className="flex items-center justify-start gap-1">
              <p className="font-Suisse-Intl font-[450] text-base text-stone-500">Points received</p>
              {!isEmpty && (
                <div className="w-fit flex items-center justify-center gap-1 rounded-md py-[2px] px-1 bg-stone-200">
                  <InfoOutlineIcon
                    height={'h-4'}
                    width={'w-4'}
                    className="h-4 w-4 text-stone-500"
                    stroke={'text-stone-500'}
                    color={'text-stone-500'}
                  />
                  <p className="font-Suisse-Intl font-medium text-sm text-stone-500">DETAILS</p>
                </div>
              )}
            </div>

            <div className="flex items-center justify-start gap-1">
              {isEmpty ? (
                <EmptyContainer classes="h-8 w-[20%] bg-stone-200" />
              ) : (
                <>
                  <p className="font-Suisse-Intl font-medium text-2xl text-stone-900">{pointsHolding}</p>
                  <div className="w-fit flex items-center justify-center gap-[2px] rounded-md p-[6px] bg-green-200 text-green-700">
                    <ArrowUpIcon
                      height={'h-4'}
                      width={'w-4'}
                      className="h-4 w-4 text-green-700"
                      stroke={'text-green-700'}
                      color={'text-green-700'}
                    />
                    <p className="font-Suisse-Intl font-medium text-sm text-green-700">{point24HrsHolding}</p>
                  </div>
                </>
              )}
            </div>
          </div>

          <div className="absolute -right-7 -bottom-10 md:-right-2 md:-bottom-10 p-0 m-0 w-52 h-40">
            <img src={successIconGroup} alt="USN icons" className="w-full h-full p-0 m-0" />
          </div>
        </div>

        <div className="w-full flex items-center justify-start gap-2 p-2 border-t-[1px] border-t-stone-300">
          <InfoOutlineIcon
            height={'h-6'}
            width={'w-6'}
            className="h-6 w-6 text-stone-500"
            stroke={'text-stone-500'}
            color={'text-stone-500'}
          />
          <p className="font-Suisse-Intl font-medium text-base text-stone-500">
            You can claim this points as a $NOON token in H1 2025
          </p>
        </div>
      </div>
    </div>
  )
}

export default Holdings
