/* eslint-disable react/react-in-jsx-scope */
import { EditIcon } from '@chakra-ui/icons'

import { useAccount } from 'wagmi'

import defaultAvatar from '../../assets/avatar.png'
import EmptyContainer from '../EmptyContainer/'

const UserProfile = () => {
  const account = useAccount()

  return (
    <div className="w-full flex flex-col gap-4 items-center justify-center md:min-h-[184px]">
      <div className="w-16 h-16">
        <img src={defaultAvatar} alt="Profile" className="w-full h-full" />
      </div>
      <div className="flex items-center justify-around gap-4">
        <div className="font-Louize font-normal text-5xl tracking-tighter text-center text-stone-900">
          Hello,
          {account.address &&
            ` ${account?.address?.slice(0, 4)}...${account?.address?.slice(account?.address?.length - 5)}`}
        </div>
        {!account.address && <EmptyContainer classes="h-12 w-32 bg-stone-200" />}
      </div>
      <button
        disabled={!account.address}
        onClick={() => {}}
        className={`w-fit flex items-center justify-center rounded-lg gap-1 py-1 px-2 bg-white ${account.address ? 'cursor-pointer' : 'cursor-not-allowed'}`}
      >
        <EditIcon
          height={'h-4'}
          width={'w-4'}
          className="w-4 h-4 text-stone-500"
          stroke={'text-stone-500'}
          color={'text-stone-500'}
        />
        <p className="font-Suisse-Intl font-medium text-sm text-stone-800">Edit</p>
      </button>
    </div>
  )
}

export default UserProfile
