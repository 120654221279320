import { sepolia, mainnet } from 'wagmi/chains'

import { TDAPPCONFIG } from '../types'

export const DAPP_CONFIG: TDAPPCONFIG = {
  [sepolia.id]: {
    SUSN_ADDRESS: '0x6c945b1e8aA77D122C3a14e29Fa5CacA805D6aD7',
    USN_ADDRESS: '0x7FbFb40b2105D6f4c54Ca702046c86946211597c',
    MINT_HANDLER_ADDRESS: '0xC517c403Cebeb728c9164C9e8A12d41ed49FCD90',
    STAKING_VAULT_ADDRESS: '0xb188e1eebd9dF6208AD7e2c1f4b74Ee82A352ec2',
    COLLATERALS: [
      {
        name: 'USDC',
        symbol: 'USDC',
        decimals: 6,
        iconPath: 'https://assets.coingecko.com/coins/images/6319/standard/usdc.png?1696506694',
        contractAddress: '0x12CA38d7f092d24a9380524B4B4b1af3fAc85D98',
        assetsIconName: 'USDC',
      },
      {
        name: 'USDT',
        symbol: 'USDT',
        decimals: 18,
        iconPath: 'https://assets.coingecko.com/coins/images/325/standard/Tether.png?1696501661',
        contractAddress: '0x6d17C66753e94A7Fc30979bEB47e1058B459ad8e',
        assetsIconName: 'USDT',
      },
    ],
    DATA_REFRESH_INTERVAL: 1000,
    GAS_LIMITS: {
      approve: 46382,
      mint: 177251,
      stake: 125148,
      unstake: 70197,
      withdraw: 76942,
    },
  },
  [mainnet.id]: {
    SUSN_ADDRESS: '0x6086d52F28c7b7481d8aE0FAdD4349cbB608C2Bd',
    USN_ADDRESS: '0xdA67B4284609d2d48e5d10cfAc411572727dc1eD',
    MINT_HANDLER_ADDRESS: '0x34a2798D47b238A7CbA9D87D49618DEE6C4D999F',
    STAKING_VAULT_ADDRESS: '0x6086d52F28c7b7481d8aE0FAdD4349cbB608C2Bd',
    COLLATERALS: [
      {
        name: 'USDC',
        symbol: 'USDC',
        decimals: 6,
        iconPath: 'https://assets.coingecko.com/coins/images/6319/standard/usdc.png?1696506694',
        contractAddress: '0xdAC17F958D2ee523a2206206994597C13D831ec7',
        assetsIconName: 'USDC',
      },
      {
        name: 'USDT',
        symbol: 'USDT',
        decimals: 6,
        iconPath: 'https://assets.coingecko.com/coins/images/325/standard/Tether.png?1696501661',
        contractAddress: '0xdAC17F958D2ee523a2206206994597C13D831ec7',
        assetsIconName: 'USDT',
      },
    ],
    DATA_REFRESH_INTERVAL: 1000,
    GAS_LIMITS: {
      approve: 46382,
      mint: 177251,
      stake: 125148,
      unstake: 70197,
      withdraw: 76942,
    },
  },
}
